import React, { useState, useEffect } from 'react'
import { Button } from '@material-ui/core';
import { db } from 'local-server/db';
import { AlertDialog } from 'components/Alert';
import { message } from 'components/notify';
import { useSelectorT } from 'store';
import { getUserProfile } from './actions';
import { useDispatch } from 'react-redux';




export default function Settings() {
  const [alertOpen, setAlertOpen] = useState(false)
  const backup = () => {
    saveTxt(db.value(), `Octopus_Words_backup_${new Date().toLocaleString()}.json`)
    message('Data downloaded', {
      autoHideDuration: 2000,
    })
  }


  const settingState = useSelectorT(state => state.settings)
  const profile = settingState.userProfile

  return <div>
      <Button           variant="outlined"
  onClick={backup}>backup</Button>
      <Button           variant="outlined"
  onClick={()=>{
        setAlertOpen(true)
       
      }}>clear all data</Button>
      <AlertDialog 
      isOpen={alertOpen}
      setOpen={setAlertOpen}
      confirmBtnTxt={'delete'}
      description={'Do you want to delete all the data?'}
      confirmAction={() => {
        localStorage.clear()
        window.location.assign("/")
      }}

      ></AlertDialog>
      
      <br/>
      Octopus word
      <br/>
      build at {process.env.REACT_APP_BUILD_AT}
      <br/>
      <br/>
      {profile && (<div>
        <h3>profile:</h3>
        <p>id: {profile._id}</p>
        <p>email/user name: {profile.email || profile.username}</p>
        <Button variant="contained" onClick={async ()=>{
          const resp: any = await fetch('//localhost:8155/user/signout', {
            mode: 'cors',
            credentials: 'include',
            method: 'POST',
          }).then(v => v.json())
          if (resp.success){
            window.location.reload()
          }
        }}>Sign Out</Button>
      </div>)}

      <pre>
        
      {JSON.stringify(profile,null,2)}
      </pre>
      <br/>
      {/* 由于某些 "ad" 开头的单词请求会被部分 去广告软件 屏蔽（蜜汁规则），请关闭去广告软件。本站不会添加任何广告。 */}
  </div>
}



function saveTxt(content: string | Object, fileName: string) {
  const text = typeof content === 'string' ? content : JSON.stringify(content, null, 2)
  const url = URL.createObjectURL(
    new Blob([text], {
      type: 'text/plain'
    })
  )

  const linkEl = document.createElement('a')
  linkEl.download = fileName
  linkEl.href = url
  linkEl.style.cssText = 'display: none !important;'
  document.body.appendChild(linkEl)
  linkEl.click()
  setTimeout(() => document.body.removeChild(linkEl))
}
