import { createActionThunk } from 'redux-thunk-actions'

import { addLearned, todayNewServer } from 'local-server/learned'
import { message } from 'components/notify';
import delayedMessage from 'utils/delayedMsg';
import { api, isErrResp } from 'api';


export const addWord = createActionThunk('ADD_WORD', async function (word) {
  const resp = await delayedMessage(api.addWord(word))

  if (isErrResp(resp) || !resp.word) {
    message(`Error at adding "${word}": ${resp.error}`, { variant: 'error', autoHideDuration: 2500 })
    throw new Error()
  }
  message(`"${resp.word}" has been added`, { variant: 'success', autoHideDuration: 1500 })
  return resp.word ? resp : null
})


export const getTodayListAct = createActionThunk('getTodayList', async function() {
    const resp = await api.getTodayList()
    return resp
})