import React from 'react'
import C from './index'



const data = [
    {header: '2019-06-12', data: [
        {word: 'helli', date: '1523423844000'},
        {word: 'hello', date: '1523423824000'},
        {word: 'world', date: '1523423843000'},
    ]},
]

let list = `rape
              screw
              stall
              complicate
              radiator
              ratio
              wound
              import
              canyon
              disagreement
              quotation
              son
              fear
              place
              cricket
              infant
              irritated
              palm
              culturally
              sold out
              grasp
              fierce
              perspective
              gym
              prevention
              illustrate
              oral
              stepfather
              input
              ant
              unfriendly
              sports centre
              rifle
              beg
              heavily
              draw
              file
              theoretical
              fiction
              right
              fraud
              assumption
              stranger`.split('\n').map(v => v.trim()).filter(v => v)

export default function example() {
    function grouper (v, i) {
        return (i / 10 | 0) + '0+'
    }
    return (
        <div style={{
            width: '280px',
            height: '500px',
            display: 'flex',
            border: 'green solid 1px'
        }}>

        <C data={list} ></C>
        </div>
    )
}

