import { useCallback, useState } from 'react';


export function useListInitOffset(listID: string): [number, (offset: number) => void] {
    const storageKey = 'list-offsets'

    // 存取的都是所有列表的对象
    const [initOffset, _setListOffset] = useState<number>(() => {
        const item = window.localStorage.getItem(storageKey)
        return item ? JSON.parse(item)[listID] : undefined
    })


    const saveOffset = useCallback((offset: number) => {
        const item = window.localStorage.getItem(storageKey)
        const allLists = item ? JSON.parse(item) : {}
        allLists[listID] = offset
        window.localStorage.setItem(storageKey, JSON.stringify(allLists))
        // _setListOffset(offset)
    }, [_setListOffset, listID])

    // initOffset 只生成一次，并不是state
    return [initOffset, saveOffset]
}