import React, { useEffect, useState } from 'react'

import Downshift from 'downshift'
import { Search, ISearchProps } from 'components/Search'
import { Paper, MenuItem, Theme, Divider } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'
import ListItem from 'components/WordList/ListItem'
import debounce from 'lodash/debounce'
import { fzSearch } from './fzsearch'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      '& > #downshift-menu:focus-within + span input': {
        width: 130,
        color: '#000000A0'
      }
    },
    paper: {
      position: 'absolute',
      outline: 'none',
      // marginTop: '3px',
      marginTop: -4,
      left: -6,
      right: -6,
      borderRadius: 18,
      padding: '46px 6px 8px 0',
      boxShadow: '0px 1px 8px 0px #aec9d060'
    },

    divider: {
      padding: '6px 0',
      margin: '0 4px 0 10px'
    },
    noItem: {
      color: '#aaa',
      fontSize: 15,
      marginLeft: 22,
      marginBottom: 4
    }
  })
)

function matchStartWith(data: string[], input: string) {
  if (!input) {
    return []
  }

  const exact = data.find(w => w === input)
  const exactAsAry = exact ? [exact] : []
  const part = data
    .filter(word => word.toLowerCase().startsWith(input.toLowerCase()))
    .slice(0, 3)
  return Array.from(new Set(exactAsAry.concat(part))).slice(0, 3)
}

function getSuggestions(data: string[], input: string) {
  if (input.length < 3) {
    return []
  }
  return fzSearch(data, input)
}

export function SearchWithComplete({
  data,
  search
}: {
  data: string[]
  search: ISearchProps['search']
}) {
  const classes = useStyles({})
  console.log('rerender')
  return (
    <div>
      <Downshift
        onChange={word => {
          word && search({ word, for: word })
        }}
        id="downshift"
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          selectedItem
        }) => {
          const { onBlur, onFocus, ...inputProps } = getInputProps({})

          const startWith = matchStartWith(data, inputValue)
          const suggestions = getSuggestions(data, inputValue!)
            .filter(sug => !startWith.includes(sug))
            .filter((v, i) => i + 1 + startWith.length <= 7)

          return (
            <div className={classes.container}>
              <div {...getMenuProps()}>
                {isOpen && startWith.length + suggestions.length ? (
                  <Paper tabIndex={-1} className={classes.paper} square>
                    {/* bool值直接作为children会报warning */}
                    <>
                      {startWith.map((suggestion, index) =>
                        renderSuggestion({
                          suggestion,
                          index,
                          itemProps: getItemProps({ item: suggestion }),
                          highlightedIndex,
                          selectedItem
                        })
                      )}
                      {Boolean(startWith.length) || (
                        <div className={classes.noItem}>No match</div>
                      )}

                      <div className={classes.divider}>
                        <Divider />
                      </div>
                      {Boolean(suggestions.length) || (
                        <div className={classes.noItem}>No suggestion</div>
                      )}

                      {suggestions.map((suggestion, index) =>
                        renderSuggestion({
                          suggestion,
                          index: index + startWith.length,
                          itemProps: getItemProps({ item: suggestion }),
                          highlightedIndex,
                          selectedItem
                        })
                      )}
                    </>
                  </Paper>
                ) : null}
              </div>
              <Search
                onChange={inputProps.onChange}
                onKeyDown={inputProps.onKeyDown}
                value={inputProps.value}
                onBlur={onBlur}
                search={search}
              />
            </div>
          )
        }}
      </Downshift>
    </div>
  )
}

interface IRenderSuggestionProps {
  suggestion: string
  index: number
  itemProps: any
  highlightedIndex: number
  selectedItem: any
}

function renderSuggestion(suggestionProps: IRenderSuggestionProps) {
  const {
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem
  } = suggestionProps
  const isHighlighted = highlightedIndex === index
  const isSelected = (selectedItem || '').indexOf(suggestion) > -1

  return (
    <ListItem
      key={suggestion}
      {...itemProps}
      wordItem={{ word: suggestion, id: suggestion }}
      selected={isHighlighted}
    />
  )
}
