import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import TodayIcon from '@material-ui/icons/Today'
import HistoryIcon from '@material-ui/icons/History'
import HomeIcon from '@material-ui/icons/Home'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'

import PersonIcon from '@material-ui/icons/Person'

import styles from './style.module.scss'

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  NavLink
} from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { useSelectorT } from 'store';
import { User } from 'components/User'
import { useDispatch } from 'react-redux'
import { getUserProfile } from 'components/Settings/actions'

function MenuLink({ label, to, activeOnlyWhenExact, icon }) {
  const Icon = icon
  return (
    <Route
      path={to}
      exact={activeOnlyWhenExact}
      children={({ match }) => {
        const color = match ? 'primary' : 'inherit'
        return (
          <NavLink to={to} activeClassName="selected">
            <ListItem disableGutters button>
              <div className={styles.labeledIcon}>
                <Icon color={color} />
                <Typography variant="subtitle2" color={color}>
                  {label}
                </Typography>
              </div>
            </ListItem>
          </NavLink>
        )
      }}
    />
  )
}

function ActivityBar({ open }) {
  const links = [
    { label: 'Home', icon: HomeIcon },
    { label: 'Due', icon: TodayIcon },
    { label: 'Learned', icon: require('@material-ui/icons/DateRange').default },
    // { label: 'Learned', icon: require('@material-ui/icons/ViewList').default },
    { label: 'Lib', icon: LibraryBooksIcon },
    // { label: 'History', icon: HistoryIcon }
  ]
  const linksEnd = [
    { label: 'Settings', icon: require('@material-ui/icons/Settings').default }
  ]

  const todayCount = useSelectorT(state => state.today.list).length
  const userLoged = !!useSelectorT(state => state.settings.userProfile)
  
  return (
    <div className={styles.ActivityBar}>
      <Drawer
        variant="permanent"
        open={open}
        className={styles.collapse}
        classes={{ paper: clsx({ [styles.collapse]: true }, styles.position) }}
      >
        <List>
          {links.map(({ label, icon }) => (
            <MenuLink
              activeOnlyWhenExact={true}
              to={`/${label.toLowerCase()}`}
              label={label + (label === "Home" ? ` ${todayCount}` : '')}
              icon={icon}
              key={label}
            />
          ))}
        </List>

        {/* bar bottom */}
        <List className={styles.listEnd}>
          {/* {userLoged || <UserLogin />} */}

          {linksEnd.map(({ label, icon }) => (
            <MenuLink
              activeOnlyWhenExact={true}
              to={`/${label.toLowerCase()}`}
              label={label}
              icon={icon}
              key={label}
            />
          ))}
        </List>
      </Drawer>
    </div>
  )
}

ActivityBar.propTypes = {
  open: PropTypes.bool
}

export default ActivityBar



function UserLogin(){
  const [open, setOpen] = useState(false)

  const dispatch = useDispatch()
  useEffect(() => {
     dispatch(getUserProfile())
  }, [])

  
  return (
    <>
      <ListItem disableGutters button onClick={() => {
        setOpen(true)
      }}>
        <div className={styles.labeledIcon}>
          <PersonIcon color="primary" />
          <Typography variant="subtitle2" color="primary">
            Log In
          </Typography>
        </div>
      </ListItem>
      {open && <User {...{open, setOpen}}></User>}

    </>
  )
}