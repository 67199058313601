import produce from 'immer'
import {
  T_FETCH_WORD_INFO,
  T_HISTORY_GO,
  T_SET_ENTRY_TITLE,
  T_CHANGE_WORD_ENTRY,
  fetchLearnedInfo_
} from 'components/WordEntry/actions'
import { WordItem } from 'local-server/learned';
import { addWord } from 'components/TodayAddList/actions';

interface IWordInfo {
  word: string,
  /** longman 词条的title, 有时word是title的变形 */
  wordEntryTitle?: string, 
  for?: any,
  isReview?: boolean,
  [x: string]: any,
}

/** 已添加单词的详情, 可能为 null */
interface LearnedInfo {
  
  [x: string]: any,
}

const defaultState = {
  wordInfo: <IWordInfo> { word: 'welcome' },
  history: <IWordInfo[]> [],
  historyAt: -1,
  /** 已添加单词的详情, 可能为 null */
  learnedInfo: <LearnedInfo | null> null,
}

export type IWordEntryState = typeof defaultState

export default function (state = defaultState, action) {
  switch (action.type) {
    case T_CHANGE_WORD_ENTRY: {
      return produce(state, state => {
        if (action.wordInfo.word === state.wordInfo.word){
          // 不会重新加载，有些数据就不生成，所以增量更新
          Object.assign(state.wordInfo, action.wordInfo)
          return
        }

        state.wordInfo = action.wordInfo

        state.learnedInfo = null
        // push history
        state.history.push({ ...action.wordInfo, date: new Date().toISOString()});
        state.historyAt = state.history.length - 1;
        if (state.history.length > 1000) {
          state.history.shift()
          state.historyAt--
        }

      })
    }
    case T_FETCH_WORD_INFO: {
      return produce(state, state => {
        // state.learnedInfo = action.learnedInfo
      })
    }
    case addWord:
    case fetchLearnedInfo_.SUCCEEDED: {
      return {
        ...state,
        learnedInfo: action.payload
      }
    }


    case T_HISTORY_GO: {
      return produce(state, state => {
        state.historyAt += action.offset
        // change wordEntry
        state.wordInfo = state.history[state.historyAt]
      })
    }

    case T_SET_ENTRY_TITLE :{
      return produce(state, state => {
          state.wordInfo.wordEntryTitle = action.wordEntryTitle
      })
    }
    default:
      return state
  }
}
