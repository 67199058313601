import { yd2Dom } from './yd2Dom';
import { getResourceUrl } from './getResourceUrl';
import { senceExtend } from './senceExtend';
import { ENV_DICT_URL } from 'env';

/** 获取单词数据， 返回词条dom。不关心组件状态 */
export const fetchNewWordDOM: (word: string) => Promise<HTMLDivElement> = async function (word) {
  let url = ENV_DICT_URL;
  let ydReq = fetch(`${url}yd/${word}.json`);
  // let ydReq = fetch(`https://dict.youdao.com/jsonapi?q=${word}`, {
  //   cache: 'force-cache'
  // })
  // let html = await fetch(`https://www.ldoceonline.com/dictionary/${word}`, {
  //   cache: 'force-cache'
  // }).then(v => v.text())
  // let correctSpell
  // let spellCheckMatch = html.match(
  //   /class="search_title">Did you mean:.*?<a href="\/search\/direct\/\?q=(.*?)">/s
  // )
  // if (spellCheckMatch) {
  //   let resp = await fetch(
  //     `https://www.ldoceonline.com/search/english/direct/?q=${word}`
  //   )
  //   let wordCorr = resp.url.match(/\/dictionary\/(.+)/)[1]
  //   ydReq = fetch(`https://dict.youdao.com/jsonapi?q=${wordCorr}`, {
  //     cache: 'force-cache'
  //   })
  //   html = await resp.text()
  // }
  // let entry = html.match(
  //   /<div class="entry_content".*?End of DIV entry_content-->/s
  // )[0]
  let entryReq = await fetch(`${url}word-html/${word}.html`, {
    // cache: 'force-cache'
  });

  if (entryReq.status === 404) {
    throw new Error(ERR_404);
  }
  const entry = await entryReq.text();

  let container = document.createElement('div');
  container.innerHTML = entry;

  // add audio click event
  container.querySelectorAll('[data-src-mp3]').forEach(speaker => {
    speaker.addEventListener('click', function () {
      this.style.color = 'lightblue';
      setTimeout(() => {
        const audio = new Audio(getResourceUrl(this.dataset.srcMp3));
        audio.play()
          .then(() => {
            setTimeout(() => {
              this.style.color = null;
            }, 150);
          })
          .catch(err => console.error(err));
      }, 0);
    });
  });

  try {
    let ydData = await ydReq.then(j => j.json());
    yd2Dom(ydData['longman.wordList'], container.firstElementChild);
  } catch (e) {
    console.log('未能append yd数据：');
    console.error(e);
  }
  
  senceExtend(container);

  return container.firstElementChild as HTMLDivElement;
};
export const ERR_404 = '404 not found';
