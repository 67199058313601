import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  Link
} from 'react-router-dom'

let components = {
  WordList: require('components/WordList/example').default,
  Header: require('components/Header').default,
  ActivityBar: require('components/ActivityBar/').default
}
export default function DebugComponents() {
  return (
    <div>
      {Object.entries(components).map(([name, cpt]) => {
        return <Route path={`/debug/${name}`} component={cpt} />
      })}

      <Route
        path="/"
        render={() => {
          return (
            <div
              style={{
                position: 'fixed',
                right: '50px',
                bottom: '50px',
                padding: '16px',
                boxShadow: '0 2px 7px #00000026',
                backgroundColor: '#ffffffdd'
              }}
            >
              <div>
                <Link to="/">🏠Home</Link>
              </div>
              {/* {Object.entries(components).map(([name]) => {
                return (
                  <li>
                    <Link to={`/debug/${name}`}>{name}</Link>
                  </li>
                )
              })} */}

              <A
                onClick={() => {
                  let str = JSON.stringify(require('learned-mock--1.json'))
                  localStorage.db_learned = str
                }}
              >
                load learned
              </A>
              <A
                onClick={() => {
                  localStorage.clear()
                }}
              >
                clean localstorage
              </A>

              <div
                onClick={() => {
                  window.location.reload(true)
                }}
              >
                reload
              </div>
            </div>
          )
        }}
      />
    </div>
  )
}

function A({ onClick, children }) {
  return (
    <div>
      <a href="" onClick={onClick}>
        {children}
      </a>
    </div>
  )
}
