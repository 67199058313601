
import React, { useCallback, useState } from 'react'
import { Paper, Typography, Button, Menu, MenuItem, Tooltip } from '@material-ui/core';
import dayjs from 'dayjs'

import styles from './InfoPopUp.module.scss'
import { useDispatch } from 'react-redux';


export default function InfoPopUp({deleteWord: confirmDelete, learnedInfo }) {
    const dispatch = useDispatch()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const deleteClickHandler = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);

    }, [learnedInfo])


    return (
        <Paper className={styles.InfoPopUp}>
            Lv: {learnedInfo.lv}
            <br />
            <br />
            reviews:
            <br />
            next at x days later
                {
                learnedInfo.reviews.map(review => {
                    return <Typography>{dayjs(review.date).format('YYYY-MM-DD')} to Lv {review.lv}</Typography>
                })
            }
            <br />
            Add at  {
                dayjs().diff(dayjs(learnedInfo.addAt).startOf('day'), 'day')
            } days ago
            {/* {dayjs(learnedInfo.addAt).format('YYYY-MM-DD')} */}
            <br />
            <br />
            <Tooltip enterDelay={500} title="Shortcut: CMD + Backspace">

            <Button variant="outlined"
                onClick={deleteClickHandler}
                style={{ color: 'red' }}>Delete</Button>
            </Tooltip>

            <Menu
                anchorEl={anchorEl}
                disableScrollLock
                anchorOrigin={{horizontal: "right", vertical:"center"}}
                open={Boolean(anchorEl)}
                onClose={() => {setAnchorEl(null)}}
                style={{marginLeft: '12px'}}
            >
                <MenuItem onClick={confirmDelete}>Confirm Delete</MenuItem>
            </Menu>
        </Paper>
    )
}
