import produce from 'immer'
import { T_ReviewDone } from 'components/DueList/actions';


const defaultState = {
    list: [],
    listLoading: false
}


export default function (state = defaultState, action) {
    switch (action.type) {
        case "GET_DUE_LIST_SUCCEEDED": {
            return produce(state, (state) => {
                state.list = action.payload
            })
        }
        case T_ReviewDone: {
            return produce(state, (state) => {
                state.list = action.list
            })
        }
        default:
            return state
    }
}
