import produce from 'immer'
import { T_DELETE_WORD } from './actions'
import { createActionThunk } from 'redux-thunk-actions'
import dayjs from 'dayjs'
import {groupBy} from 'lodash'
import { api } from 'api'

export const fetchLearnedList = createActionThunk('FETCHLEARNEDLIST', async () => {
  
  const resp = await api.getLearnedLists()
  // throw 1
  return resp
})




const defaultState = {
  list: [],
  listLoading: false,
}

export type ILearnedListState = typeof defaultState

export default function (state = defaultState, action): ILearnedListState {
  switch (action.type) {
    case T_DELETE_WORD: {
      return produce(state, (state) => {
        // state.list = action.list
      })
    }
    case fetchLearnedList.START: {
      return {
        ...state,
        listLoading: true,
      }
    }
    case fetchLearnedList.SUCCEEDED: {
      return {
        ...state,
        list: action.payload || []
      }
    }
    case fetchLearnedList.ENDED:{
      return {
        ...state,
        listLoading: false,
      }
    }
    default:
      return state
  }
}
