import { useCallback, useState } from 'react';


export function useListSelected(listID: string): [string, (selected: string) => void] {
    const storageKey = 'list-selected'

    // 存取的都是所有列表的对象
    const [listSelected, setListSelected] = useState<string>(() => {
        const item = window.localStorage.getItem(storageKey)
        return item ? JSON.parse(item)[listID] : ''
    })


    const setListState = useCallback((selectedID: string) => {
        const item = window.localStorage.getItem(storageKey)
        const allLists = item ? JSON.parse(item) : {}
        allLists[listID] = selectedID
        window.localStorage.setItem(storageKey, JSON.stringify(allLists))

        setListSelected(selectedID)
    }, [setListSelected, listID])

    return [listSelected, setListState]
}
