import React, { Component, useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './longman.style.modify.scss'
import './longman.raw.scss'
import { changeWordEntry, fetchLearnedInfo_, setEntryTitle } from 'components/WordEntry/actions'
import { blur } from './addBlurTarget'
import clsx from 'clsx'
import { CircularProgress } from '@material-ui/core'
import { DelayAppear } from 'components/DelayAppear'
import { Notfound } from './Notfound';
import { IReduxState } from 'store'
import { transIpaDom } from './transIpaDom/transIpaDom'
import { getResourceUrl } from './getResourceUrl'
import { fetchNewWordDOM, ERR_404 } from './fetchNewWordDOM'

interface IProps {
  blured?: boolean
  word: string
  [x: string]: any
}

interface IState {
  loading: boolean
  notfound: boolean
  [x: string]: any
}


class WordEntry extends Component<IProps, IState> {
  state = {
    loading: false,
    notfound: false
  }
  elEntryContainer = React.createRef<HTMLDivElement>()

  wordLinkClickHandler = (e) => {
    e.preventDefault()
    let word = e.currentTarget.dataset.word
    if (e.currentTarget.classList.contains('crossRef')) {
      window.open(`https://www.ldoceonline.com/dictionary/${word}`);
      return
    }
    this.props.changeWord({ word })
  }

  numKeyPlayHandler = e => {
    if (+e.key > 0 && !e.repeat) {
      const el: any = document.querySelectorAll('[data-src-mp3]')[+e.key]
      el && el.click()
    }
  }

  /** 获取单词；进行处理；管理状态；加载到DOM */
  loadWord = async (word: string) => {
    let notfound
    this.setState({ loading: true, notfound: false })

    let wordEntryDom: HTMLDivElement
    try {
      wordEntryDom = await fetchNewWordDOM(word)
    } catch (e) {
      if (e.message === ERR_404) {
        notfound = true
      } else {
        throw e
      }
    }

    if (word !== this.props.word) {
      console.info('忽略过时的请求')
      return
    }
    this.setState({ loading: false })

    // 404则不再继续
    if (notfound) {
      this.setState({ notfound: true })
      const entry_content = this.elEntryContainer.current.querySelector(
        '.entry_content'
      )
      entry_content && entry_content.remove()
      return
    }

    // blur target
    blur(wordEntryDom)

    Array.from(
      wordEntryDom.querySelectorAll('a[href^="/dictionary/"]')
    ).forEach((el: HTMLAnchorElement) => {
      // 单词链接点击
      el.addEventListener('click', this.wordLinkClickHandler)

      // 去掉#锚链接
      const url = new URL(el.href).pathname
      el.dataset.word = url.replace('/dictionary/', '')
      el.removeAttribute('href')
    })

    // 转换 cloudflare 的 img 链接
    Array.from(
      wordEntryDom.querySelectorAll(
        'img[src^="https://d27ucmmhxk51xv.cloudfront.net/media"]'
      )
    ).forEach((img: HTMLImageElement) => {
      img.src = getResourceUrl(img.src)
    })

    // 点击音标发音
    transIpaDom(wordEntryDom.querySelector<HTMLSpanElement>(`.PronCodes > .PRON`));


    // 
    const pagetitle = wordEntryDom.querySelector<HTMLHeadingElement>('h1.pagetitle').innerText
    this.props.setEntryTitle(pagetitle)



    // load to dom
    const entry_content = this.elEntryContainer.current.querySelector(
      '.entry_content'
    )
    entry_content
      ? entry_content.replaceWith(wordEntryDom)
      : this.elEntryContainer.current.appendChild(wordEntryDom)


    // ? create dictentry-card
    Array.from(wordEntryDom.querySelectorAll('.dictionary>.dictentry')).forEach(
      entry => {
        const el = document.createElement('div')
        el.classList.add('dictentry-card')
        entry.before(el)
        el.appendChild(entry)
      }
    )


    // 自动发音
    const btn = wordEntryDom.querySelector(
      '.Head [data-src-mp3].amefile'
    ) as HTMLSpanElement
      ; (window as any).__hack_autoPlayPromise
? (window as any).__hack_autoPlayPromise(getResourceUrl(btn.dataset["src-mp3"]))
: (btn && btn.click())
      
    // 

    document.documentElement.scrollTo(0, 0)
  }

  pasteHandler = e => {
    e.clipboardData.items[0].getAsString(clipboard => {
      const trimed = clipboard.trim()
      // 非常像是个单词才跳转
      if (/^[a-z]+$/i.test(trimed)) {
        this.props.changeWord({ word: trimed })
      }
    })
  }

  async componentDidMount() {
    this.props.isLogedin && this.props.fetchLearnedInfo(this.props.word)
    const entryLoad = this.loadWord(this.props.word)
    await entryLoad

    window.addEventListener('paste', this.pasteHandler)
    window.addEventListener('keypress', this.numKeyPlayHandler)
  }

  componentWillUnmount() {
    window.removeEventListener('paste', this.pasteHandler)
    window.removeEventListener('keypress', this.numKeyPlayHandler)
    console.log('wordentry will unmount')
  }

  componentDidUpdate = async prevProps => {
    console.log('wordentry didupdate')
    if (this.props.word !== prevProps.word) {
      this.props.isLogedin && this.props.fetchLearnedInfo(this.props.word)

      await this.loadWord(this.props.word)
    }
  }

  render() {
    const blured = this.props.blured
    return (
      <div
        className={clsx('word-entry-container', blured && 'blured')}
        ref={this.elEntryContainer}
      >
        {this.state.loading && (
          <DelayAppear delay={300}>
            <div className="center-by-parent">
              <div className="loading-card">
                <CircularProgress className={''} />
              </div>
            </div>
          </DelayAppear>
        )}
        {this.state.notfound && (
          <Notfound word={this.props.word} onWordClick={word => this.props.changeWord({ word })}></Notfound>
        )}
        <div>
          <div className="entry_content" />
        </div>
      </div>
    )
  }
}

export default connect(
  (state: IReduxState) => {
    return {
      isLogedin: !!state.settings.userProfile
    }
  },
  dispatch => {
    return bindActionCreators(
      {
        changeWord: changeWordEntry,
        fetchLearnedInfo: fetchLearnedInfo_,
        setEntryTitle,
      },
      dispatch
    )
  }
)(WordEntry)
