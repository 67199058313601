import React, { useEffect, useState, useCallback, useMemo } from 'react'

import WordList, { IWordListProps } from 'components/WordList'

import { useListSelect } from 'hooks/useListSelect'
import { useListInitOffset } from 'hooks/useListScrollOffset'

const listCache = {}
export default function LibWordList({ list }) {
  //   fetch data
  const [listData, setListData] = useState([])
  const [listLoading, setListLoading] = useState(false)
  useEffect(() => {
    if (!listCache[list.url]) {
      listCache[list.url] = fetch(list.url).then(v => v.json())
      setListLoading(true)
    }
    listCache[list.url].then(v => {
      setListData(v)
      setListLoading(false)
    })
  }, [list])

  let dataSource = useMemo(() => {
    const grouped: IWordListProps['dataSource'] = []
    listData.forEach((item: string, i) => {
      const groupIndex = Math.floor(i / 10)
      if (!grouped[groupIndex]) {
        grouped[groupIndex] = { title: groupIndex + '0+', _id: String(groupIndex), items: [] }
      }
      grouped[groupIndex].items.push({ word: item, id: item })
    })
    return grouped
  }, [listData])

  // listState
  const listCacheID = 'LibList/' + list.id
  const [selectedID, notShowing, select] = useListSelect(listCacheID)
  const [initOffset, saveOffset] = useListInitOffset(listCacheID)

  // 生成 outline
  const outline: IWordListProps['outline'] = []
  dataSource.forEach((group, i) => {
    if (i % 10 === 0) {
      outline.push({
        title: String(Math.floor(i / 10)),
        targetGroupID: group._id,
      })
    }
  })

  return (
    <WordList
      initOffset={initOffset}
      setOffset={saveOffset}
      outline={outline}
      loading={listLoading}
      dataSource={dataSource}
      selectedID={selectedID}
      notShowing={notShowing}
      select={select}
    />
  )
}
