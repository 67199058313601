import React, { useState, useCallback, useRef, useEffect } from 'react'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'

import styles from './style.module.scss'
import { useKey } from 'react-use';

export interface ISearchProps {
  search: (wordInfo: { word: string; for?: any }) => void
  onChange?: Function
  onKeyDown?: Function
  value?: any
  onBlur?: any
}
export function Search({ search, onChange, onBlur, onKeyDown, value }: ISearchProps) {
  const [goingFocus, setGoingFocus] = useState(false)

  const keyPressHandler = useCallback(
    e => {
      e.nativeEvent.stopImmediatePropagation()
      if (e.key === 'Enter') {
        e.preventDefault()
        void (e.target as HTMLInputElement).blur()
        const word = (e.target as HTMLInputElement).value
        search({ word: word, for: word })
      }
    },
    [search]
  )

  function focusInput() {
    const inputEl = inputRef.current
    if (inputEl) {
      const selection = window.getSelection().toString().trim()
      inputEl.focus()

      if (selection) {
        // https://github.com/facebook/react/issues/10135#issuecomment-401496776
        let setNativeValue = (element, value) => {
          const { set: valueSetter } = Object.getOwnPropertyDescriptor(element, 'value') || {} as any
          const prototype = Object.getPrototypeOf(element)
          const { set: prototypeValueSetter } = Object.getOwnPropertyDescriptor(prototype, 'value') || {} as any

          if (prototypeValueSetter && valueSetter !== prototypeValueSetter) {
            prototypeValueSetter.call(element, value)
          } else if (valueSetter) {
            valueSetter.call(element, value)
          } else {
            throw new Error('The given element does not have a value setter')
          }
        }
        setNativeValue(inputEl, selection)
        inputEl.dispatchEvent(new Event('input', { bubbles: true }))
      }
    }
  }

  // hot key focus
  const inputRef = useRef<HTMLInputElement>()
  const shortCutHandler = useCallback((e: KeyboardEvent) => {
    if (!e.metaKey || !e.shiftKey || e.repeat || e.ctrlKey) {
      return
    }
    debugger
    focusInput();
  }, [inputRef.current])
  useKey('f', shortCutHandler, {}, [shortCutHandler])


  const focusHandler = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    setGoingFocus(true)
    const inputEL = e.target as HTMLInputElement
    inputEL.setSelectionRange(0, inputEL.value.length)
    setTimeout(() => {
      setGoingFocus(false)
    }, 1000)
  }, [])

  useEffect(() => {
    window.addEventListener('focus', () => {
      focusInput();
    })
  }, [])

  return (
    <span className={styles.search}>
      <div className={styles.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        inputRef={inputRef}
        placeholder="Search"
        classes={{
          root: styles.inputRoot,
          input: styles.inputInput
        }}
        onFocus={focusHandler}
        onBlur={onBlur}
        // 由于元素位置移动，mouseup可能会干扰选择
        onMouseUp={e => {
          if (goingFocus) {
            setGoingFocus(false)
            e.preventDefault()
          }
        }}
        spellCheck={false}
        autoComplete={'off'}
        value={value}
        onChange={onChange as any}
        onKeyDown={(e) => {
          e.stopPropagation()
          onKeyDown(e)
        }}
        onKeyPress={keyPressHandler}
        onPaste={e => e.stopPropagation()}
      />
    </span>
  )
}
