import { ENV_DICT_URL } from "env";

// convert cloudflare url
export function getResourceUrl(url: string) {
  // /media/english/ameProns/prestige1.mp3
  const pathname = new URL(url).pathname;
  const path = pathname.replace("/media/english/", "");
  const [category, filename, ...more] = path.split("/");

  let resURL;
  if (category === "ameProns") {
    resURL = ENV_DICT_URL + "hwd/ame/" + filename;
  } else if (category === "breProns") {
    resURL = ENV_DICT_URL + "hwd/bre/" + filename;
  } else if (category === "exaProns") {
    resURL = ENV_DICT_URL + "exa/bre/" + filename;
  } else if (category === "illustration") {
    resURL = ENV_DICT_URL + "img/" + filename;
  } else {
    console.error("unexpect url: ", url);
  }

  if (more.length) {
    console.error("unexpect url: ", url);
  }

  return resURL;
}

Object.assign(window, { getResourceUrl });
