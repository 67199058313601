import Fzsearch from 'fz-search'


const searcherCache = new Map


export function fzSearch(data: string[], query: string) {
    console.time('fzsearchAll')
    let searcher = searcherCache.get(data)
    if (!searcher) {
        console.log('new Searcher')
        searcher = new Fzsearch({ source: data, output_map: 'root' })
        searcherCache.set(data, searcher)
    }

    let result = searcher.search(query).slice(0, 10)
    console.timeEnd('fzsearchAll')

    console.log(result)
    return result.map(v => v.item) as string[]
}


// 恒定返回同一个 Promise 对象
let promise_cache: Promise<string[]>
export function getAutoCompleteList(): Promise<string[]> {
    let promiseRt

    if (promise_cache) {
        promiseRt = promise_cache
    } else {
        let resolve
        promiseRt = new Promise(r => resolve = r)
        promise_cache = promiseRt
        fetch('/autocomplete-list.json')
            .then(v => v.json())
            .then(v => {
                const words = v.filter(word => word.length > 2)
                resolve(words)
            })
    }

    return promiseRt
}

Object.assign(window, { getAutoCompleteList })

