import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
    palette: {
        primary: {
          light: '#56c8d8',
          main: '#0097a7',
          dark: '#006978',
          contrastText: '#fff',
        },
        secondary: {
          light: '#4ebaaa',
          main: '#00897b',
          dark: '#005b4f',
          contrastText: '#000',
        },
      },
  });