import React, { useCallback } from 'react'
import Button from '@material-ui/core/Button'

import styles from './style.module.scss'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'

import { reviewNext } from 'components/DueList'

import { ReviewState } from 'components/MainContent'
import { reviewDone } from 'components/DueList/actions'
import { useSelectorT } from 'store';

Object.assign(window, {reviewDone})


interface Props {
  reviewState: ReviewState
  setReviewState: React.Dispatch<React.SetStateAction<ReviewState>>
}

export default function ReviewOption(props: Props) {
  const { reviewState, setReviewState } = props
  const dispatch = useDispatch()

  const wordInfo = useSelectorT(state => state.wordEntry.wordInfo)

  const handleGoodClick = useCallback(
    e => {
      setReviewState('done_good')
      setTimeout(() => {
        setReviewState('origin')
      }, 950)
      dispatch(
        reviewDone({
          word: wordInfo.word,
          lv: undefined,
          retry: 0
        })
      )
      setTimeout(() => {
        const hasNext = reviewNext()
        if (hasNext) {
          setTimeout(() => {
            setReviewState('recall')
          })
        } else {
          setReviewState('all_done')
        }
      }, 1000)
    },
    [dispatch, setReviewState, wordInfo.word]
  )

  const handleBadClick = useCallback(() => {
      setReviewState('done_bad')
      setTimeout(() => {
        setReviewState('origin')
      }, 950)
      
      setTimeout(() => {
        reviewNext(true)
        setReviewState('recall')
      }, 1000)
    }, [setReviewState])

    // 🚫🐞
  ;(window as any).setReviewState = setReviewState

  return (
    <div className={clsx(styles.ReviewOption, 's-' + reviewState)}>
      <div className="main">
        <h4>尝试回想</h4>
        <span className="secondary">可以点击模糊的释义或例句来偷看 👀</span>
      </div>

      <div className="main">
        <h4>还记得吗？</h4>
        <span className="secondary">先浏览一遍释义，然后进行反馈</span>
      </div>
      <div className="main">
        <h4>已全部复习完毕 🎉</h4>
      </div>

      <div className="buttons b1">
        <Button
          onClick={() => setReviewState('feedback')}
          variant="outlined"
          color="primary"
        >
          好了
        </Button>
      </div>
      <div className="buttons b2">
        <Button
          onClick={handleGoodClick}
          className="feedback-good"
          variant="outlined"
          color="primary"
        >
          还不错
        </Button>
        <Button
          onClick={handleBadClick}
          className="feedback-bad"
          color="primary"
        >
          不太好
        </Button>
        <span
          className={clsx('tip-good', {
            'tip-active': reviewState === 'done_good'
          })}
        >
          预计下次复习在 x 天后{' '}
        </span>
        <span
          className={clsx('tip-bad', {
            'tip-active': reviewState === 'done_bad'
          })}
        >
          今天将会再次复习
        </span>
      </div>
    </div>
  )
}
