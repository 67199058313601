import React, { useRef, useEffect, useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'

import Divider from '@material-ui/core/Divider'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import SearchIcon from '@material-ui/icons/Search'

import { changeWordEntry } from 'components/WordEntry/actions'
import WordList, { IWordListProps } from 'components/WordList'
import styles from './style.module.scss'
import { useListState } from 'hooks/useListState'
import { groupData } from 'components/WordList/groupData'
import { useListSelected } from 'hooks/useListSelected'
import { useListSelect } from 'hooks/useListSelect'
import { useListInitOffset } from 'hooks/useListScrollOffset'
import { CSSTransition } from 'react-transition-group'
  ; import AnimatedTitle from 'components/ListHeader/AnimatedTitle';
import { useSelectorT } from 'store';
import { fetchLearnedList } from './reducer'

(window as any).howMany = new Map()

export default function LearnedList(props) {
  const dispatch = useDispatch()

  const [selectedID, notShowing, select] = useListSelect('LearnedList')
  const [initOffset, saveOffset] = useListInitOffset('LearnedList')

  useEffect(() => {
    localStorage.loged && dispatch(fetchLearnedList())
  }, [])

  const [lists, listLoading]: [any[], any] = useSelectorT(({ learned }) => [learned.list, learned.listLoading])
  const dataSource: IWordListProps["dataSource"] = useMemo(() => {
    return lists.map((list): IWordListProps['dataSource'][0] => {
      return {
        title: list.name,
        _id: list._id,
        items: list.words.map(({ word }) => ({ word, id: word }))
      }
    })
  }, [lists])

  // const outline = useMemo(() => {
  //   return lists.map((list): IWordListProps['outline'][0] => ({
  //     title: list.name,
  //     targetGroupID: list._id
  //   }))
  // }, [lists])


  const wordListRef = useRef<WordList>()
  return (
    <div className={styles.hoverable}>
      <header className={styles.header}>
        <Tooltip title={`total: ${lists.length}`} enterDelay={500}>
          <AnimatedTitle>Learned</AnimatedTitle>
        </Tooltip>

        <Tooltip title="go to top" enterDelay={800}>
          <IconButton
            className={styles.hoverShow}
            onClick={() => wordListRef.current.scrollToTop()}
            size="small"
          >
            <ArrowUpwardIcon style={{ fontSize: '18px' }} />
          </IconButton>
        </Tooltip>
      </header>

      <WordList
        initOffset={initOffset}
        setOffset={saveOffset}
        ref={wordListRef}
        dataSource={dataSource}
        // data.map(w => ({...w, rk: w.__rank, d: w.addAt.replace('2019', '')}))
        loading={listLoading}
        selectedID={selectedID}
        notShowing={notShowing}
        select={select}
      />
    </div>
  )
}
