import { message } from "components/notify"


type errResp = {
    error: string
}

export interface UserProfile {
    _id?: string,
    username?: string,
    email?: string,
}

export interface DueList extends Array<{
    _id: string,
    word: string,
    [x: string]: any,
}> { }

const BASEURL = `http://localhost:8155`

async function f(url: string, option: RequestInit) {
    return fetch(url, {
        mode: 'cors',
        credentials: 'include',
        ...option
    }).then(v => {
        if (v.status === 401) {
            localStorage.removeItem('loged')
            message('401 Unauthorized', { variant: 'error', autoHideDuration: 2000 })
        }
        return v.json()
    })
}

const appFetch = {
    async get<TSuccess>(path: string, payload?: object, config?: RequestInit) {
        const url = new URL(BASEURL + path)
        url.search = new URLSearchParams(payload as any).toString()

        const resp: TSuccess | errResp = await f(url as any, {
            method: 'GET',
            ...config
        })

        return resp
    },
    async post<TSuccess>(path: string, payload?: object, config?: RequestInit) {
        const resp: TSuccess | errResp = await f(BASEURL + path, {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json',
            },
            body: JSON.stringify(payload),
            ...config,
        })

        return resp
    },
    async delete<TSuccess>(path: string, payload?: object, config?: RequestInit) {
        const resp: TSuccess | errResp = await f(BASEURL + path, {
            method: 'DELETE',
            headers: {
                "Content-Type": 'application/json',
            },
            body: JSON.stringify(payload),
            ...config,
        })
        return resp
    },
    async put<TSuccess>(path: string, payload?: object, config?: RequestInit) {
        const resp: TSuccess | errResp = await f(BASEURL + path, {
            method: 'PUT',
            headers: {
                "Content-Type": 'application/json',
            },
            body: JSON.stringify(payload),
            ...config,
        })

        return resp
    },
}


export function isErrResp(resp: any): resp is errResp {
    return Boolean((resp as errResp).error)
}






/** api们 发送请求， 并负责管理 传入和返回的类型。 */
export const api = {
    async signup(payload: {
        username?: string,
        email?: string,
        password: string,
    }) {
        const resp = await appFetch.post<UserProfile>('/user/signup', payload)
        if ((resp as any)._id) {
            localStorage.setItem('loged', 'true')
        }
        return resp
    }


    , async login(payload: {
        username?: string,
        email?: string,
        password: string,
    }) {

        const resp = await appFetch.post<UserProfile>('/user/login', payload)
        if ((resp as any)._id) {
            localStorage.setItem('loged', 'true')
        }
        return resp
    }

    , async getWordInfo(word: string) {
        return await appFetch.get<{
            _id: string,
            word: string,
            [x: string]: any,
        } | {}>('/learned', { word })
    },
    getTodayList() {
        return appFetch.get('/learned/pending')
    },
    
    async getLearnedLists() {
        return appFetch.get('/learned')
    },
    async addWord(word: string) {
        return await appFetch.post<{
            _id: string,
            word: string,
            [x: string]: any,
        }>('/learned', { word })
    }
    , async deleteWord(word: string) {
        return await appFetch.delete('/learned', { word })
    }



    , async getDueList() {
        return await appFetch.get<DueList>('/learned/due')
    }

    , async reviewDone(listId: string) {
        return await appFetch.post<DueList>('/learned/review', {
            listId,
        })
    }


    , async getProfile() {
        return await appFetch.get<UserProfile>('/user/profile')
    }

}
