import React, { useState } from 'react'
import { SnackbarProvider, WithSnackbarProps, useSnackbar } from 'notistack'

export let message: WithSnackbarProps['enqueueSnackbar'] = null
export let closeMessage = null

function C() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  message = enqueueSnackbar
  closeMessage = closeSnackbar
  return <></>
}

export function Notistack() {
  return (
    <SnackbarProvider maxSnack={3}>
      <C />
    </SnackbarProvider>
  )
}
