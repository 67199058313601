import { createActionThunk } from 'redux-thunk-actions'


import { message } from 'components/notify';
import delayedMessage from 'utils/delayedMsg';
import { api, isErrResp } from 'api';


export const T_DELETE_WORD = "learned/deleteWord"


export const deleteWord = createActionThunk('DELETE_WORD', async function (word: string) {
  const resp = await delayedMessage(api.deleteWord(word))

  if (isErrResp(resp)) {
    throw new Error()
  }

  message(`"${word}" has been deleted`, { variant: 'success', autoHideDuration: 1500 })
})
