import React, { useState, useRef } from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import styles from './User.module.scss'
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { getUserProfile } from './Settings/actions';
import { Paper, Tab, Tabs, Grid, Container } from '@material-ui/core';
import { isErrResp, api } from 'api';

interface Props {
    open: boolean
    setOpen: (boolean) => void
}
export function User({ open, setOpen }: Props) {

    const [contentPage, setContentPage] = useState<'SignupOrLogin' | 'login' | 'signup' | 'success'>('SignupOrLogin')

    const [formError, setFormError] = useState<boolean | '// something is worng in the form'>(false)
    const [formInput, setFormInput] = useState({
        name: '',
        password: '',
    })

    const handleClose = () => {
        setOpen(false);
    };

    const dialogRef = useRef()


    return (
        <Dialog ref={dialogRef} className={clsx({ [styles.invalid]: formError })} classes={{ paper: styles.Paper }} BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }} open={open} onClose={handleClose} >
            <DialogTitle id="form-dialog-title">User {{
                login: '- Log In',
                signup: '- Sign Up'
            }[contentPage]}</DialogTitle>

            {/* 👉DialogContent */}
            {
                {
                    'SignupOrLogin': <SignupOrLogin {...{ setContentPage }}></SignupOrLogin>,
                    'signup': <SignUpContent {...{ handleClose, dialogRef, formInput, setFormInput, setFormError, setContentPage }}>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                    </SignUpContent>,
                    'login': <LoginContent {...{ handleClose, dialogRef, formInput, setFormInput, setFormError, setContentPage }}>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                    </LoginContent>,
                    'success': (
                        <DialogContent>
                            <h1>success</h1>
                            <DialogContentText>
                                操作成功
                                </DialogContentText>
                        </DialogContent>
                    )
                }
                [contentPage]
            }
            <pre>
                {JSON.stringify(formInput, null, 2)}
            </pre>


        </Dialog>
    )
}



function SignupOrLogin({ setContentPage }) {
    return (
        <DialogContent>
            <Grid container justify="center" style={{ height: '100%', paddingTop: '70px' }}>
                <Grid container item direction="column" xs={6} spacing={2}>
                    <Grid item>
                        <Button fullWidth onClick={() => setContentPage('signup')} variant="contained" color="primary">
                            Sign Up
                    </Button>
                    </Grid>
                    <Grid item>
                        <Button fullWidth onClick={() => setContentPage('login')} variant="outlined" color="primary">
                            Log In
                    </Button>


                    </Grid>
                    <Grid item>
                        <DialogContentText align="center">
                            注册帐号或登录已有帐号
                    </DialogContentText>
                    </Grid>
                </Grid>
            </Grid>
        </DialogContent>
    )
}

function LoginContent({ dialogRef, handleClose, children, formInput, setFormInput, setContentPage }) {
    const [errMsg, setErrMsg] = React.useState('')
    const [nameType, setNameType] = useState<'username' | 'email'>(formInput.name.includes('@') ? 'email' : 'username')
    const dispatch = useDispatch()

    async function submit() {
        const resp = await api.login({ [nameType]: formInput.name, password: formInput.password })

        if (isErrResp(resp)) {
            await new Promise(r => setTimeout(r, 200))
            setErrMsg(resp.error)
            dialogRef.current.classList.add(styles.invalid)
            setTimeout(() => {
                dialogRef.current.classList.remove(styles.invalid)
            }, 500)
            return
        } else {
            setContentPage('success')
            setTimeout(() => {
                handleClose()
                dispatch(getUserProfile())
            }, 500)
        }
    }

    return (
        <>
            <DialogContent>

                <DialogContentText>
                    登录
                </DialogContentText>

                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Email or User name"
                    type={{ email: 'email', username: 'text' }[nameType]}
                    fullWidth
                    value={formInput.name}
                    onChange={(e) => {
                        const value = e.currentTarget.value
                        if (value.includes('@')) {
                            setNameType('email')
                        } else {
                            setNameType('username')
                        }

                        setFormInput({
                            ...formInput,
                            name: e.currentTarget.value,
                        })
                    }}
                />
                <TextField
                    margin="dense"
                    id="name"
                    label="Password"
                    type="password"
                    fullWidth
                    value={formInput.password}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            submit()
                        }
                    }}
                    onChange={(e) => {
                        setFormInput({
                            ...formInput,
                            password: e.currentTarget.value,
                        })
                    }}
                />

                <DialogContentText>

                    <Button color="primary" onClick={() => {
                        setContentPage('signup')
                    }}>

                        注册一个新帐号
    </Button>
                </DialogContentText>




                {/* error message */}
                {errMsg && <DialogContentText color="error">
                    {errMsg}
                </DialogContentText>}

            </DialogContent>

            <DialogActions>
                {children}
                <Button color="primary" variant="contained" onClick={submit}>
                    Log In
                    </Button>
            </DialogActions>
        </>
    )
}


function SignUpContent({ children, handleClose, dialogRef, formInput, setFormInput, setContentPage, setFormError }) {
    const [errMsg, setErrMsg] = React.useState('')
    const dispatch = useDispatch()

    const [nameType, setNameType] = useState<'email' | 'username'>(() => {
        if (formInput.name) {
            return formInput.name.includes('@') ? 'email' : 'username'
        }
        return "email"
    })

    function _debug_get() {
        fetch('//localhost:8155/learned', { mode: 'cors', credentials: 'include' }).then(v => v.text()).then(console.log)
    }

    async function submit() {
        const resp = await api.signup({ [nameType]: formInput.name, password: formInput.password })

        if (isErrResp(resp)) {
            await new Promise(r => setTimeout(r, 200))
            setErrMsg(resp.error)
            dialogRef.current.classList.add(styles.invalid)
            setTimeout(() => {
                dialogRef.current.classList.remove(styles.invalid)
            }, 500)
            return
        } else {
            setContentPage('success')

            setTimeout(() => {
                handleClose()
                dispatch(getUserProfile())
            }, 500)
        }


    }

    function switchNameClick(to) {
        setNameType(to)
        setFormInput({ ...formInput, name: '' })
        setTimeout(() => {
            nameInputRef.current.focus()
        })
    }

    const nameInputRef = useRef<HTMLInputElement>()
    return (
        <>
            <DialogContent>

                <DialogContentText>
                    {{
                        email: '尽量使用真实邮箱，不会用作其它用途或泄漏给他人。',
                        username: '用户名注册'
                    }[nameType]}
                </DialogContentText>


                <TextField
                    inputRef={nameInputRef}
                    autoFocus
                    margin="dense"
                    id="name"
                    label={{ email: 'Email Address', username: 'User Name' }[nameType]}
                    type={{ email: 'email', username: 'text' }[nameType]}
                    fullWidth
                    value={formInput.name}
                    onChange={(e) => {
                        setFormInput({
                            ...formInput,
                            name: e.currentTarget.value,
                        })
                    }}
                />

                <TextField
                    margin="dense"
                    id="name"
                    label="Password"
                    type="password"
                    fullWidth
                    value={formInput.password}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            submit()
                        }
                    }}
                    onChange={(e) => {
                        setFormInput({
                            ...formInput,
                            password: e.currentTarget.value,
                        })
                    }}
                />

                <DialogContentText>

                    <Button color="primary" onClick={() => {
                        setContentPage('login')
                    }}>

                        登录已有帐号
    </Button>
                </DialogContentText>

                <DialogContentText>
                    {{
                        email: (<>
                            不想使用邮箱注册？
                            <span style={{ cursor: 'pointer', color: '#0097a7' }} onClick={() => switchNameClick('username')}>
                                使用用户名注册
                        </span>
                        </>
                        ),
                        username: (
                            <>
                                你正在使用用户名注册，推荐
                        <span style={{ cursor: 'pointer', color: '#0097a7' }} onClick={() => switchNameClick('email')}>
                                    使用邮箱注册
                        </span>
                            </>
                        )
                    }[nameType]

                    }
                </DialogContentText>


                {/* error message */}
                {errMsg && <DialogContentText color="error">
                    {errMsg}
                </DialogContentText>}

            </DialogContent>

            <DialogActions>
                {children}

                <Button onClick={submit} variant="contained" color="primary">
                    Sign Up
</Button>
            </DialogActions>
        </>
    )
}



function genRandomPsw(len: number = 6): string {
    if (!(len >= 6)) {
        throw new Error('length should be greater than 6')
    }

    const CHARS = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz'


    function getRandomInt(min, max) {
        //The maximum is exclusive and the minimum is inclusive
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    }

    const result = [...new Array(len)].map(() => {
        return CHARS[getRandomInt(0, 58)]
    }).join('')

    return result
}
Object.assign(window, { genRandomPsw })