import React, { Component } from 'react'
import { throttle, findIndex, flatten, memoize, debounce } from 'lodash'
import clsx from 'clsx'
import { VariableSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import CircularProgress from '@material-ui/core/CircularProgress'

import Fab from '@material-ui/core/Fab'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import ListItem from './ListItem'
import styles from './style.module.scss'

export type DataPropItem = { word: string; id: string;[x: string]: any }
export type GroupedData = Array<[string, DataPropItem[]]>
export type GroupedDat_ = { title: string, _id: string, items: DataPropItem[] }[]

type Outline = {
  title: string,
  targetGroupID: string,
}

interface IState {
  locateDirection: 'up' | 'none' | 'down'
  activeOutline: string
}

export interface IWordListProps {
  loading?: boolean
  selectedID?: string
  // data: GroupedData
  outline?: Outline[]
  dataSource?: GroupedDat_
  review?: boolean
  [x: string]: any
}

export default class WordList extends Component<IWordListProps, IState> {
  _resizeHandler: any
  state: IState = {
    locateDirection: 'none',
    activeOutline: null,
  }
  selectedRef = React.createRef()
  listRef = React.createRef<any>()
  outlineRef = React.createRef<HTMLUListElement>()
  scrollContainerRef = React.createRef<HTMLDivElement>()
  // const
  groupHeaderHeight = 38
  itemHeight = 28
  scrollToTop = () => {
    if (this.scrollContainerRef.current) {
      this.scrollContainerRef.current.scrollTo({ top: 0 })
    }
  }
  scrollToSelected = () => {
    // TODO: undefined check
    const offsetTop = this._getOffsetTopByID(this.props.selectedID)
    const containerHeight = this.scrollContainerRef.current.offsetHeight

    const scrollTo = offsetTop - containerHeight / 2
    if (!Number.isNaN(scrollTo)) {
      this.listRef.current.scrollTo(scrollTo, 0)
    }
  }

  // 键盘移动选择
  updownHandler = e => {
    const optionMap = {
      ArrowDown: 1,
      ' ': 1,
      ArrowUp: -1
    }

    const offset = optionMap[e.key]

    if (offset !== undefined) {
      if (['INPUT', 'TEXTAREA'].includes(e.target.tagName)) {
        return
      }

      e.preventDefault()

      if (e.repeat) {
        return
      }

      const unGrouped = flatten(this.props.data.map(group => group[1]))

      const selectedIndex = findIndex(unGrouped, {
        id: this.props.selectedID
      })
      const nextItem = unGrouped[selectedIndex + offset]
      if (nextItem) {
        this.props.select(nextItem)
        setTimeout(() => {
          this.scrollToSelected()
        })
      }
    }
  }

  // goNextToReview = () => {
  //   const unGrouped = flatten(
  //     this.getGroupedData(this.props.data).map(group => group[1])
  //   )
  //   const reviewingIndex = findIndex(unGrouped, {
  //     id: this.props.selectedID
  //   })

  //   const resort = unGrouped
  //     .slice(reviewingIndex + 1)
  //     .concat(unGrouped.slice(0, reviewingIndex + 1))

  //   const nextItem = resort.find(item => !item.dueReviewed)

  //   if (nextItem) {
  //     this.props.select({...nextItem, isReview: true})
  //     setTimeout(() => {
  //       this.scrollToSelected()
  //     })
  //     return true
  //   }
  //   return false
  // }

  saveOffsetDebounced = debounce(offset => {
    this.props.setOffset && this.props.setOffset(offset)
  }, 500)

  componentDidMount() {
    // resize时检查
    window.addEventListener(
      'resize',
      (this._resizeHandler = () => {
        this.scrollHandler()
      })
    )

    // 键盘移动选择
    window.addEventListener('keydown', this.updownHandler)
  }
  componentWillUnmount() {
    console.log('wordlist will unmount')
    window.removeEventListener('resize', this._resizeHandler)
    window.removeEventListener('keydown', this.updownHandler)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedID !== this.props.selectedID) {
      this.setState({ locateDirection: 'none' })
    }
  }

  listItemMapRender = wordItem => {
    let { selectedID, notShowing, select } = this.props
    let selected = selectedID === wordItem.id
    return (
      <ListItem
        selected={selected}
        notShowing={notShowing}
        noSelect={this.props.review}
        select={select}
        wordItem={wordItem}
        key={wordItem.id}
      />
    )
  }

  // 根据listitem的id, 算出所处位置
  _getOffsetTopByID = id => {
    if (!id) {
      return undefined
    }
    const dataSource = this.props.dataSource

    let pos = 0
    for (let { items } of dataSource) {
      pos += this.groupHeaderHeight
      const itemI = findIndex(items, { id })
      if (itemI === -1) {
        pos += items.length * this.itemHeight
      } else {
        pos += itemI * this.itemHeight
        return pos
      }
    }
    return undefined
  }

  // 用以检查 定位按钮 是否需要显示, 并保存滚动位置
  scrollHandler = throttle(() => {
    const el = this.scrollContainerRef.current
    // 空列表等情况，ref可能不存在
    if (!el) {
      return
    }

    this.saveOffsetDebounced(el.scrollTop)

    if (!this.props.selectedID) {
      return
    }

    const selectedOffsetTop = this._getOffsetTopByID(this.props.selectedID)

    if (selectedOffsetTop === undefined) {
      return
    }
    const locateDirection = this.state.locateDirection

    if (el.scrollTop - 28 + 20 > selectedOffsetTop) {
      if (locateDirection !== 'up') {
        this.setState({ locateDirection: 'up' })
      }
    } else if (el.scrollTop + el.clientHeight < selectedOffsetTop) {
      if (locateDirection !== 'down') {
        this.setState({ locateDirection: 'down' })
      }
    } else {
      if (locateDirection !== 'none') {
        this.setState({ locateDirection: 'none' })
      }
    }
  }, 50)

  // react-window onItemsRendered， 用来检查和设置 outline 联动
  itemRenderHandler = ({ visibleStartIndex }) => {
    if (!this.props.outline) {
      return
    }
    console.log('itemRenderHandler: ' + visibleStartIndex)
    const visibleStartListID = this.props.dataSource[visibleStartIndex]._id
    const visibleSecondList = this.props.dataSource[visibleStartIndex + 1]
    
    const isOutlineChanged = this.state.activeOutline !== visibleStartListID
    const isOutlineList = !!this.props.outline.find(outlineItem => outlineItem.targetGroupID === visibleStartListID)
    if (isOutlineChanged && isOutlineList) {
      this.setState({ activeOutline: visibleStartListID })
      setTimeout(() => {
        this.outlineRef.current
          .querySelector('.' + styles.now).scrollIntoView({ block: 'center' })
      })
    }
  }

  render() {
    let { dataSource = [], emptyRender } = this.props
    let locateDirection = this.state.locateDirection


    // render loading
    if (this.props.loading) {
      return (
        <div className={styles.rootContainer}>
          <div className={styles.emptyContainer}>
            <CircularProgress size={32} />
          </div>
        </div>
      )
    }

    /* render empty list */
    if (!dataSource.length) {
      return (
        <div className={styles.rootContainer}>
          <div className={styles.emptyContainer}>
            {emptyRender ? emptyRender() : <EmptyTip />}
          </div>
        </div>
      )
    }

    return (
      <div className={styles.rootContainer}>
        {/* 定位按钮 */}
        <Fab
          onClick={this.scrollToSelected}
          variant="extended"
          size="small"
          color={'primary'}
          className={clsx(
            styles.locate,
            styles.locateUp,
            locateDirection !== 'up' && styles.hide
          )}
        >
          <ExpandLessIcon />
        </Fab>

        <Fab
          onClick={this.scrollToSelected}
          variant="extended"
          size="small"
          color={'primary'}
          className={clsx(
            styles.locate,
            styles.locateDown,
            locateDirection !== 'down' && styles.hide
          )}
        >
          <ExpandMoreIcon />
        </Fab>

        {/* outline */}
        {this.props.outline && (
          <ul className={styles.outline} ref={this.outlineRef}>
            {this.props.outline
              .map((outlineItem, i) => {
                return (
                  <li
                    className={clsx({
                      [styles.now]: (params => {
                        return this.state.activeOutline === outlineItem.targetGroupID
                      })()
                    })}
                    onClick={() => {
                      const index = this.props.dataSource.findIndex(list => {
                        return list._id === outlineItem.targetGroupID
                      })
                      this.listRef.current.scrollToItem(index, 'start')
                    }}
                    key={outlineItem.targetGroupID}
                  >
                    {outlineItem.title}
                  </li>
                )
              })}
          </ul>
        )}

        {/* the list */}
        <AutoSizer>
          {({ height, width }) => {
            return (
              <List
                className={styles.WordList}
                height={height}
                itemCount={dataSource.length}
                itemSize={i =>
                  dataSource[i].items.length * this.itemHeight + this.groupHeaderHeight
                }
                width={width}
                ref={this.listRef}
                outerRef={this.scrollContainerRef}
                onItemsRendered={this.itemRenderHandler}
                onScroll={this.scrollHandler}
                initialScrollOffset={this.props.initOffset || 0}
              >
                {({ index, style }) => {
                  const { title, _id, items } = dataSource[index]
                  return (
                    <div key={_id} style={style}>
                      <h4 className="sticky-header" onDoubleClick={e => {
                        this.props.onTitleClick(e.currentTarget.innerText)
                      }}>{title}</h4>
                      <ul className={styles['section-day']}>
                        {items.map(this.listItemMapRender)}
                      </ul>
                    </div>
                  )
                }}
              </List>
            )
          }}
        </AutoSizer>
      </div>
    )
  }
}

function EmptyTip() {
  return <p>Empty list</p>
}
