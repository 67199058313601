import React from 'react'
import { CSSTransition } from 'react-transition-group'
import { Tooltip } from '@material-ui/core'
import styles from './style.module.scss'
import './AnimatedTitle.module.scss'

export default function AnimatedTitle({ children, ...restProps }) {
  return (
    <CSSTransition
      in={true}
      timeout={500}
      classNames="header-trans"
      appear
    >
      {/* <Tooltip title="过去每天学习的新单词" enterDelay={800}> */}
      <h3 {...restProps} className={styles.panelHeader}>{children}</h3>
      {/* </Tooltip> */}
    </CSSTransition>
  )
}
