import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'

import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import learned from 'components/LearnedList/reducer'
import today from 'components/TodayAddList/reducer'
import wordEntry, { IWordEntryState } from 'components/WordEntry/reducer'
import due from 'components/DueList/reducer'
import settings, { ISettingsState } from 'components/Settings/reducer'
import { useSelector } from 'react-redux'

export interface IReduxState {
  learned: any,
  wordEntry: IWordEntryState,
  today: any,
  due: any,
  settings: ISettingsState,
}

export type UseSelectorType = <TSelected>(
  selector: (state: IReduxState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean
) => TSelected;
// 预先定义类型，方便使用
export const useSelectorT: UseSelectorType = useSelector


const wordEntryPersistConfig = {
  key: 'root.wordEntry',
  storage: storage,
  whitelist: ['wordInfo', 'history', 'historyAt']
}

const settingsPersistConfig = {
  key: 'root.settings',
  storage: storage
}

const rootReducers = combineReducers({
  learned,
  wordEntry: persistReducer(wordEntryPersistConfig, wordEntry),
  today,
  due,
  settings,
})
const store = createStore(
  rootReducers,
  // 由于compose传入 undefined 会报错
  (window as any).__REDUX_DEVTOOLS_EXTENSION__
  ? compose(
    applyMiddleware(
      thunkMiddleware,
    ),
    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
  )
  : applyMiddleware(thunkMiddleware)

)
export default store
export const persistor = persistStore(store)
