import produce from 'immer'
import { T_SETTINGS_SETAUTOPRONOUNCE, T_SETTINGS_SETSHOWTRANS, T_RECEIVE_USER_PROFILE } from './actions';
import { UserProfile } from 'api';



const defaultState = {
    autoPronounce: true,
    showTrans: true,
    userProfile: <UserProfile>null,
}

export type ISettingsState = typeof defaultState


export default function (state = defaultState, action) {
    switch (action.type) {
        case T_SETTINGS_SETAUTOPRONOUNCE: {
            return produce(state, (state) => {
                state.autoPronounce = action.to
            })
        }
        case T_SETTINGS_SETSHOWTRANS: {
            return produce(state, (state) => {
                state.showTrans = action.to
            })
        }
        case T_RECEIVE_USER_PROFILE: {
            return produce(state, (state) => {
                state.userProfile = action.data
            })
        }
        default:
            return state
    }
}
