import { getWordLearnedInfo } from 'local-server/learned'
///<reference path="./createActionThunk.d.ts"/>

import { createActionThunk } from 'redux-thunk-actions'
import delayedMessage from 'utils/delayedMsg'
import { message } from 'components/notify'
import { api, isErrResp } from 'api'

export const T_CHANGE_WORD_ENTRY = 'WORDENTRY/CHANGE_WORD_ENTRY'
export function changeWordEntry(wordInfo: {
  word: string
  for?: any
  isReview?: boolean
}) {

  return { type: T_CHANGE_WORD_ENTRY, wordInfo: { ...wordInfo, word: wordInfo.word.toLowerCase() } }
}

export const T_FETCH_WORD_INFO = 'GET_WORD_INFO'
export function fetchLearnedInfo(word: string) {
  const learnedInfo = getWordLearnedInfo(word)
  return {
    type: T_FETCH_WORD_INFO,
    learnedInfo
  }
}

/** 获取单个单词的学习信息 */
export const fetchLearnedInfo_ = createActionThunk('FETCH_WORD_INFO', async function (word) {
  const resp = await api.getWordInfo(word)

  if (isErrResp(resp)) {
    throw new Error()
  }
  return (resp as any).word ? resp : null
})


export const T_HISTORY_GO = 'HISTORY_GO'
export function historyGo(offset: number) {
  return {
    type: T_HISTORY_GO,
    offset
  }
}


export const T_SET_ENTRY_TITLE = 'WordEntry/T_SET_ENTRY_TITLE'
/** 储存 wordentry 的标题，供 Header 使用 */
export function setEntryTitle(wordEntryTitle: string) {
  return {
    type: T_SET_ENTRY_TITLE,
    wordEntryTitle,
  }
}