import { ENV_AI_URL } from "env";

export function senceExtend(domRoot: HTMLDivElement) {
  Array.from(domRoot.querySelectorAll(".ldoceEntry .Sense")).forEach(
    (senceEl) => {
      const trigger = document.createElement("div");
      const btn = document.createElement("button");
      btn.innerHTML = `More Example By AI`;
      trigger.appendChild(btn);
      btn.addEventListener("click", async () => {
        const def = (
          trigger.closest(".Sense").querySelector(".DEF") as HTMLDivElement
        ).innerText;
        const word = trigger
          .closest(".entry_content")
          .querySelector(".pagetitle");
        const resp = await fetch(ENV_AI_URL, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            command: "genExampleByDef",
            params: {
              def,
              word: (word as HTMLDivElement).innerText,
            },
          }),
        }).then((v) => v.json());
        trigger.innerText = resp.result.content;
      });
      senceEl.appendChild(trigger);
    }
  );
}
