import low from 'lowdb'
import LocalStorage from 'lowdb/adapters/LocalStorage'
import { IDbServer } from './learned';

const adapter = new LocalStorage<IDbServer>('db_server', {
  serialize(obj) {
    return JSON.stringify(obj);
  }
})

export const db = low(adapter)

const defaults: IDbServer  =  {
    learned: [],
    server_cache: { due_list_today: ['', []] }
}
db.defaults(defaults).write()


  // 🚫🐞
  ; (window as any).db = db