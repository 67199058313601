import { useCallback } from "react";
import { useDispatch } from 'react-redux'
import { useListSelected } from "./useListSelected";
import { changeWordEntry } from "components/WordEntry/actions";
import { useSelectorT } from "store";

export function useListSelect(listID) : [string, boolean, Function] {
    const dispatch = useDispatch()
    const [selectedID, setSelectedID] = useListSelected(listID)
    const select = useCallback(
        ({ word, id }) => {
            setSelectedID(id)
            dispatch(changeWordEntry({ word, for: id }))
        },
        [dispatch, setSelectedID]
    )
    const entryWordFor = useSelectorT(state => state.wordEntry.wordInfo.for)
    const notShowing = entryWordFor !== selectedID
    return [selectedID, notShowing, select]
}