import { rank } from './rank'
import dayjs from "dayjs"
import sortBy from 'lodash/sortBy'
import shuffle from 'lodash/shuffle'

import { db } from './db'

function __logDebugInfo(cArg) {
  if (localStorage.__ow_debug__) {
    console.groupCollapsed('local-server')
    console.log([...cArg])
    console.trace()
    void (console.groupEnd as any)('local-server')
  }
}

// 加载 debug 数据
// if (process.env.NODE_ENV == 'development') {
//   const mockLearned = require('../learned-mock--1.json')
//   let ver = localStorage.__debug_learned_ver
//   // 如果生成了新的mock, 自动更新
//   if (ver !== mockLearned[0]) {
//     db.set('server_cache', { due_list_today: ['', []] }).write()
//     db.set('learned', mockLearned[1]).write()
//     localStorage.__debug_learned_ver = mockLearned[0]
//   }
// }


// get
export function getLearned() {
  return db.get('learned').value()
}



/** post 添加一个单词 */
export function addLearned({ word, addAt, lv = 0 }) {
  __logDebugInfo(arguments)

  const exist = db.get('learned').find(item => item.word === word).value()

  if (exist) {
    throw new Error('could not add word: already exist')
  }

  db.get('learned').unshift({
    word,
    addAt,
    lv,
    reviews: [],
    // id: '' + Math.random()
  }).write()

  return todayNewServer()
}

/** delete 删除一个单词 */
export function deleteWordServer(word: string) {
  __logDebugInfo(arguments)

  db.get('learned').remove({ word }).write()

  // remove from due list cache
  db.get(['server_cache', 'due_list_today', '1']).remove({ word }).write()
  
  return getLearned()
}

/** get 今日添加的单词 列表 */
export function todayNewServer() {
  return db.get('learned').filter(wordItem => dayjs().isSame(dayjs(wordItem.addAt), 'date')).value()
}




/** get 单个特定的的单词信息 */
export function getWordLearnedInfo(word: string) {
  const learned = getLearned()
  return learned.find(v => v.word === word)
}


type ReviewDoneServerArg = { 
  word: string, 
  /** 复习了后到达的层级 */
  lv?: number, 
  /** 没想起来的次数（点击了不记得） */
  retry: number 
}
// post 用户复习了某个单词，增加一条复习记录
export function reviewDoneServer(arg: ReviewDoneServerArg) {
  __logDebugInfo(arguments)

  const { word, retry, lv } = arg

  // 检查
  const dueList = getDueListServer()
  const wordInDue = dueList.find(dw => dw.word === word)
  if (!wordInDue || wordInDue.dueReviewed) {
    throw new Error('can not review')
  }


  if (lv < 0 || lv > 7) {
    throw new Error('invalid lv')
  }
  let lvBeforeReview = db.get('learned').find({ word }).value().lv

  const nextLv = lv == undefined ? lvBeforeReview + 1 : lv
  
  db.get('learned').find({ word }).get('reviews').push({
    date: new Date().toISOString(),
    retry,
    lv: nextLv,
  }).write()

  db.get('learned').find({ word }).update('lv', () => nextLv).write()

  return getDueListServer()
}


// get 当日的due 列表
// 由于数据持续变化，第一次请求必须缓存due相关信息。以保证在后续请求返回一致的列表。
export function getDueListServer() {

  let todayStr = dayjs().format('YYYY-MM-DD')
  let due_list_today = db.get(['server_cache', 'due_list_today']).value() as IServerCache['due_list_today']


  let dueList: typeof due_list_today[1]
  
  if (due_list_today[0] === todayStr) {
    dueList = due_list_today[1]
  } else {
    // 有超期未复习的单词，不能一股脑全部塞给用户。
    // 求出每天添加的平均数量，x1.3 倍

    const learning = db.get('learned').filter(w => w.lv < 7).value()
    /** 已成熟的单词 */
    dueList = learning.map((rec): CacheItem => ({
      word: rec.word,
      dueRank: rank(rec),
      dueLv: rec.lv,
      addAt: rec.addAt,
    })).filter(rec => rec.dueRank >= 1)


    /** 去重后所有已成熟的 YYYY-MM-DD 日期 */
    const days = Array.from(new Set(dueList.map(v => dayjs(v.addAt).format('YYYY-MM-DD'))))


    /**  每个数字代表那一天添加的个数 */
    const lengthsPerDay = days.map(day => {
      const itemCountThisDay = learning.filter(wordItem => dayjs(wordItem.addAt).format('YYYY-MM-DD') === day).length
      return itemCountThisDay
    })

    // 去掉20%最少的天数，求平均值
    const notIncludes = Math.floor(lengthsPerDay.length * 0.2)
    // 这些天学习的单词总数
    const sum = lengthsPerDay.sort().filter((count, i) => i >= notIncludes)
      .reduce((p, c) => p + c, 0)

    // 平均每天学习的单词数量
    const avg = sum / (lengthsPerDay.length - notIncludes) // 可能NaN
    
    // 这次返回 due 的最大数量
    let dueMaxCount = avg * 7 * 1.3

    console.log('due length: ', `${dueMaxCount}/${dueList.length}`)
    dueMaxCount = dueMaxCount || Infinity
    dueList = sortBy(dueList, 'dueRank').reverse() // .slice(0, Math.max(dueMaxCount, 70))



    // 按dueLv组内乱序
    dueList = sortBy(shuffle(dueList), 'dueLv')
    // 缓存一部分属性
    const cacheData = [todayStr, dueList]
    db.set('server_cache.due_list_today', cacheData).write()
  }

  // 填充其它信息
  return dueList.map((rec) => {
    let word = getLearned().find(word => rec.word === word.word)
    let reviews = word.reviews
    const lastReviewsAt = reviews.slice(-1).pop() && reviews.slice(-1).pop().date
    const dueReviewed = !!lastReviewsAt && dayjs(lastReviewsAt).isSame(dayjs(), 'date')
    return {
      ...rec,
      ...word,
      dueReviewed,
      _debug_lastReviewsAt: lastReviewsAt,
    }
  })
}



export interface IDbServer {
  server_cache: IServerCache,
  learned: ILearned,
}

export interface IServerCache {
  due_list_today: [string, CacheItem[]]
}

export type CacheItem = {
  word: string
  dueRank?: number
  dueLv?: number
  addAt?: string
}

export type ILearned = WordItem[]


export type WordItem = {
  word: string
  addAt: string
  lv: number
  reviews: ReviewRecord[]

  [x: string]: any
}

export type ReviewRecord = {
  date: string
  retry: number
  lv: number
}