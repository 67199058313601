import produce from 'immer'
import { getTodayListAct } from './actions'

const defaultState = {
  list: [],
  listLoading: false,
}

export type ITodayListState = typeof defaultState

export default function (state = defaultState, action) {
  switch (action.type) {

    case 'ADD_WORD_STARTED': {
      return {
        ...state,
        listLoading: true,
      }
    }
    case 'ADD_WORD_SUCCEEDED': {
      return {
        ...state,
        // list: action.payload
      }
    }
    case 'ADD_WORD_ENDED': {
      return {
        ...state,
        listLoading: false,
      }
    }

    case getTodayListAct.START: {
      return {
        ...state,
        listLoading: true,
      }
    }
    case getTodayListAct.SUCCEEDED: {
      return {
        ...state,
        list: action.payload || []
      }
    }
    case getTodayListAct.ENDED: {
      return {
        ...state,
        listLoading: false,
      }
    }

    default:
      return state
  }
}
