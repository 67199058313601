
import dayjs from 'dayjs'
import { WordItem } from './learned';

const intervalMap = [1, 1, 2, 4, 7, 15, 30]

// 目前的实现基本就是 经过天数/预期安排天数，并稍作调整
// 截止昨天的rank
export function rank(word: WordItem) {
    const { reviews = [], lv, addAt } = word
    let lvUpdateAt
    if (reviews.length === 0) {
        lvUpdateAt = addAt
    } else {
        lvUpdateAt = reviews.slice(-1).pop().date
    }

    const interval = intervalMap[lv]
    const dayPass = dayjs()
        .startOf('day')
        .diff(dayjs(lvUpdateAt).startOf('day'), 'day')
    let rank
    if (dayPass - interval >= 0) {
        rank = (dayPass + 1) / (interval + 0.3)
    } else {
        // 低于0.7则太少了
        rank = (dayPass - 1) / interval
    }
    return +rank.toFixed(2)
}