import { useState, useEffect } from "react";

export function DelayAppear({ delay, children }) {
    const [show, setShow] = useState(false)
    useEffect(() => {
      const id = setTimeout(() => {
        setShow(true)
      }, delay)
      return () => clearTimeout(id)
    }, [delay])
  
    return show && children
  }
  