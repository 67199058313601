import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface Props {
  isOpen: boolean, 
  setOpen: Function,  
  description?: string, 
  title?: string, 
  confirmBtnTxt?: string,
  confirmAction?: Function,
}
export function AlertDialog(props: Props) {
  const {isOpen, setOpen,  description, title, confirmBtnTxt, confirmAction} = props

  function handleClose() {
    setOpen(false);
  }

  function handleConfirm() {
    confirmAction && confirmAction();
    setOpen(false);

  }

  return (
      <Dialog
        open={isOpen}
        disableScrollLock
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title || 'Alert'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description || 'Confirm operation'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            {confirmBtnTxt || 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
  );
}