import React, { useRef, useCallback, useState, useEffect, useMemo } from 'react'

import WordList from 'components/WordList'
import learnedData from 'learned-mock--1.json'
import { sortBy } from 'lodash'
import { connect, useDispatch } from 'react-redux'
import { changeWordEntry } from 'components/WordEntry/actions'

import Divider from '@material-ui/core/Divider'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ShuffleIcon from '@material-ui/icons/Shuffle'
import SearchIcon from '@material-ui/icons/Search'

import styles from './style.module.scss'

import { groupData } from 'components/WordList/groupData'
// import { getTodayListAct } from 'components/TodayAddList/actions'
import { useListSelect } from 'hooks/useListSelect'
import AnimatedTitle from 'components/ListHeader/AnimatedTitle'
import { useSelectorT } from 'store';
import { getTodayListAct } from './actions'

export default function TodayList(props) {
  const dispatch = useDispatch()
  const isLogedIn = useSelectorT(state => !!state.settings.userProfile)
  // data
  useEffect(() => {
    isLogedIn && dispatch(getTodayListAct())
  }, [dispatch, isLogedIn])

  const [listData, listLoading] = useSelectorT(({today}) => [today.list, today.listLoading])
  const grouped = useMemo(
    () =>
      groupData(
        listData.map(item => ({ word: item.word, id: item.word })),
        (v, i) => {
          return 'today'
        }
      ),
    [listData]
  )

  // select
  const [selectedID, notShowing, select] = useListSelect('TodayNew')

  const wordListRef = useRef<WordList>()

  return (
    <div className={styles.hoverable}>
      <header className={styles.header}>
        <AnimatedTitle>Today New</AnimatedTitle>

        <Tooltip title="go to top" enterDelay={800}>
          <IconButton
            className={styles.hoverShow}
            onClick={() => {
              wordListRef.current.scrollToTop()
            }}
            size="small"
          >
            <ArrowUpwardIcon style={{ fontSize: '18px' }} />
          </IconButton>
        </Tooltip>
      </header>

      <WordList
        ref={wordListRef}
        data={grouped}
        selectedID={selectedID}
        notShowing={notShowing}
        select={select}
        loading={listLoading}
        emptyRender={() => {
          return (
            <div style={{ fontSize: '13px', userSelect: 'none' }}>
              <p>今天还未添加新单词</p>
              <p>
                你可以浏览单词列表，寻找一些想要学习的新单词。
              </p>
            </div>
          )
        }}
      />
    </div>
  )
}
