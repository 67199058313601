import React, { useCallback } from 'react'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import styles from './ListItemStyle.module.scss'
console.log(styles)
interface IListItemProps {
  wordItem: any
  selected?: boolean
  notShowing?: boolean
  select: ({ word, id }: { word: string; id: any }) => void
  onClick?: any;
  [x: string]: any
}

export default function ListItem(props: IListItemProps) {
  let {
    wordItem,
    selected,
    onClick,
    notShowing,
    select,
    noSelect,
    children
  } = props

  const { word, id, dueReviewed } = wordItem

  const selectHandler = useCallback(
    e => {

      // 🐞
      new Promise((r) => {
        setTimeout(() => {
          r()
        }, 500);
      }).then(() => {

        // new Audio(url as any).play().catch(e => console.error(e))
      })
      // new Promise(r => setTimeout(r, 500)).then(()=>{
      //   new Audio(` http://longman-audio.test.upcdn.net/hwd/ame/privacy.mp3`).play()
      // })



      const { word, id } = e.currentTarget.dataset
      select({ word, id })
    },
    [select]
  )

  const baseStyle = dueReviewed
    ? {
      color: '#888'
    }
    : {}

  const style = selected
    ? {
      backgroundColor: notShowing
        ? 'transparent'
        : 'hsla(194, 78%, 40%, 1)',
      color: notShowing ? 'black' : 'white',
      boxShadow: 'inset rgb(22 144 182) 0px 0px 0px 2px' 
    }
    : baseStyle

  // if (noSelect) {
  //   Object.assign(style, { cursor: 'not-allowed' })
  // }

  return (
    // ref={ref}
    <li draggable
    onDragStart={(e)=>{
      const dragImage = document.createElement('div');
      dragImage.style.cssText = `
        display: inline-block;
        font-size: 12px;
        position: fixed;
        z-index: 1210;
        border-radius: 13px;
        height: 24px;
        font-weight: bold;
        background-color: rgb(22, 144, 182);
        color: white;
        padding: 0 12px;
        top: 0;
        line-height: 24px;
        vertical-align: middle;
        left: 9999px;
      `
      dragImage.textContent = word;
      document.body.appendChild(dragImage);
      const width = dragImage.getBoundingClientRect().width;
      e.dataTransfer.setDragImage(dragImage, width / 1.5, 14);
      setTimeout(() => document.body.removeChild(dragImage), 0);
    }}
    className={styles.ListItem} style={style}>
      <span data-word={word} data-id={id} onClick={onClick || selectHandler}
      // style={{fontSize: '9px'}}
      >
        {children || word}

        {/* // 🚫🐞 remove rk */}

        {/* {children || word +  ` ${wordItem.dueRank} dlv${wordItem.dueLv}  ${wordItem.interval}d ${wordItem.daysAgo - wordItem.interval}d lv${wordItem.lv} `} */}

        {dueReviewed && (
          <CheckCircleIcon color={selected ? 'inherit' : 'primary'} />
        )}
      </span>
    </li>
  )
}
