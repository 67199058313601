import { message, closeMessage } from "components/notify"

/**
 * @description 在操作请求后延迟一段时间提供一个loading提示
 */
export default async function delayedMessage<T>(reqPromise: Promise<T>, msg = '正在操作...', delay = 500): Promise<T> {
  const symbol = {}
  const resolved = await Promise.race([
    new Promise(r => setTimeout(() => r(symbol), delay)),
    reqPromise,
  ])


  if (resolved === symbol) {
    // 超时
    const msgInst = message(msg, {
      persist: true,
    })
    let resp
    try {
      resp = await reqPromise
    } finally {
      closeMessage(msgInst)
    }
    return resp as T
  }
  return resolved as T
}
