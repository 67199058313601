import { DataPropItem, GroupedData } from ".";
export type groupByFunc = (v: any, i: number) => string

export function groupData(data: DataPropItem[], groupBy: groupByFunc) {
    const result: GroupedData = []

    if (typeof groupBy !== 'function') {
        const groupCount: number = groupBy || 10
        groupBy = (v, i) => {
            return Math.floor(i / groupCount) + '0+'
        }
    }
    data.forEach((v, i, ary) => {
        /**  groupName */
        let groupName = (groupBy as groupByFunc)(v, i)
        let index = result.findIndex(
            ([existGroupName]) => groupName === existGroupName
        )
        if (index === -1) {
            result.push([groupName, [v]])
        } else {
            result[index][1].push(v)
        }
    })

    return result
}