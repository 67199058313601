import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  Link
} from 'react-router-dom'
import * as serviceWorker from './serviceWorker'
import { PersistGate } from 'redux-persist/integration/react'

import { ThemeProvider } from '@material-ui/styles'
import { Provider } from 'react-redux'
import store, { persistor } from './store'
import './index.css'
import App from './App'

import theme from './theme.js'
import DebugComponents from './debug-components'

import { setAutoFreeze } from "immer"
setAutoFreeze(false) // 和 redux-persist 冲突


ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <Router>
          <Route path="/" component={App} />
        </Router>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
