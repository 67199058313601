import React, { useState } from 'react'
import { fzSearch, getAutoCompleteList } from 'components/Header/fzsearch';
import { useAsync } from 'react-use'

interface IProps {
    word: string,
    onWordClick: (word: string) => void,
}

export function Notfound({word, onWordClick}:IProps) {
    const suggestions = useAsync(async ()=>{
        return fzSearch(await getAutoCompleteList(), word).slice(0, 4)
    }, [word])

    console.log(suggestions)

    return (
        <div className="notfound-card">
            <img height="362" src="/img/notfound.svg" />
            <p>Could not find "{word}" in longman dictionary</p>
            { !suggestions.loading && suggestions.value.length && (
                <p>Did you mean: {  suggestions.value.map(word => {
                        return (
                            <a key={word} style={{color: 'blue'}} onClick={() => onWordClick(word)}>{word} </a>
                        )
                    })
                    }</p>
            )
            }
          
            <p style={{color: '#ccc'}}>// TODO: word variants match </p>
          </div>
    )
}
