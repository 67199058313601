import React, { useRef, useCallback, useMemo, useEffect, useState } from 'react'

import WordList, { GroupedData, GroupedDat_ } from 'components/WordList'

import { useDispatch } from 'react-redux'
import { changeWordEntry } from 'components/WordEntry/actions'

import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'

import styles from './style.module.scss'

import flatten from 'lodash/flatten'

import { getDueList } from 'components/DueList/actions'

import dayjs from 'dayjs'
import AnimatedTitle from 'components/ListHeader/AnimatedTitle'
import { CSSTransition } from 'react-transition-group';
import { useSelectorT } from 'store';
import { useListSelect } from 'hooks/useListSelect';
import { useListInitOffset } from 'hooks/useListScrollOffset';
import { reviewDone } from 'components/DueList/actions'

/** 如果当前单词fail, feedback为true. 返回值表示是否还有下一个 */
export let reviewNext: (feedback?: boolean) => boolean
export default function DueList() {
  const dispatch = useDispatch()

  const [selectedID, notShowing, selectItem] = useListSelect('Duelist')

  const select = useCallback(
    ({ word, id, isReview }) => {
      selectItem({ word, id })
      dispatch(changeWordEntry({ word, for: id, isReview }))
    },
    [dispatch]
  )

  useEffect(() => {
    localStorage.loged && dispatch(getDueList())
  }, [dispatch])

  const wordInfo = useSelectorT(state => state.wordEntry.wordInfo)
  const isWordEntryReview = wordInfo.isReview
  const list = useSelectorT(state => state.due.list)



  let intervalMap = [1, 1, 2, 4, 7, 15, 30]

  const groupedData: GroupedDat_ = useMemo(() => {
    return list.map(list => {
      return {
        title: list.name,
        items: list.words.map(({ word }) => ({ word, id: word }))
      }
    })
  }, [list])

  // const groupedData = useMemo(() => {
  //   return groupData(data, (v, i) => {
  //     const map = [
  //       'Bronze(1)',
  //       'Silver(2)',
  //       'Gold(第3次复习)',
  //       'Platinum(4)',
  //       'Diamond(5)',
  //       'Master(6)',
  //       'Challenger(7)'
  //     ]
  //     // return '1'
  //     return map[v.dueLv] + ' ' + dayjs(v.addAt).format('MM-DD') + ' ▸'+v.rk
  //   })
  // }, [data])

  // const [reviewOrderList, setReviewOrderList] = useState<string[]>([])
  // useEffect(() => { // useWatch, 只关心有哪些单词，不关心其它属性
  //   const flattenData = flatten(groupedData.map(group => group[1]))
  //   const initOrderList = flattenData.filter(item => !item.dueReviewed).map(item => item.word)
  //   setReviewOrderList(initOrderList)
  //   console.log(initOrderList)
  // }, [list.map(item => item.word).join()])

  // reviewNext = useCallback((feedback) => {
  //   let currWord
  //   if (isWordEntryReview) {
  //     currWord = reviewOrderList.shift()
  //   }
  //   if (feedback) {
  //     // 需要重新安排这个单词
  //     reviewOrderList.splice(Math.ceil(reviewOrderList.length / 2), 0, currWord)
  //     console.log(reviewOrderList)
  //   }


  //   const nextItem = reviewOrderList[0]


  //   if (nextItem) {
  //     select({ word: nextItem, id: nextItem, isReview: true })
  //     setTimeout(() => {
  //       wordListRef.current.scrollToSelected()
  //     })
  //     return true
  //   }
  //   return false
  // }, [reviewOrderList, isWordEntryReview, select, wordInfo.id])

  const [initOffset, saveOffset] = useListInitOffset('Duelist')
  const remains = list.filter(v => !v.dueReviewed).length
  const wordListRef = useRef<WordList>()
  return (
    <div className={styles.hoverable}>
      <header className={styles.header}>
        <AnimatedTitle>Due</AnimatedTitle>

        <Tooltip title="go to top" enterDelay={800}>
          <IconButton
            className={styles.hoverShow}
            onClick={() => wordListRef.current.scrollToTop()}
            size="small"
          >
            <ArrowUpwardIcon style={{ fontSize: '18px' }} />
          </IconButton>
        </Tooltip>
      </header>
      <span className="remains">
        <span className={remains ? 'red-dot' : 'green-dot'}>●</span>
        {remains ? (
          <span>
            今天还有<strong>{remains}</strong>
            个单词需要回顾
          </span>
        ) : (
            <span>没有需要复习的内容了</span>
          )}
      </span>
      <CSSTransition in={true}
        timeout={500} classNames="list-trans" appear>
        <WordList
          ref={wordListRef}
          initOffset={initOffset}
          setOffset={saveOffset}
          review
          dataSource={groupedData}
          selectedID={selectedID}
          notShowing={notShowing}
          select={select}
        />
      </CSSTransition>
    </div>
  )
}

// const data = sortBy(learnedData.filter(v => v.__rank >= 1), 'lv')
