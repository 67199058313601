import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { Link, Route } from 'react-router-dom'

import Divider from '@material-ui/core/Divider'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import SearchIcon from '@material-ui/icons/Search'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

import { changeWordEntry } from 'components/WordEntry/actions'

import LibWordList from './LibWordList'

import styles from './style.module.scss'
import produce from 'immer'
import AnimatedTitle from 'components/ListHeader/AnimatedTitle'

const wordLists = {
  'CET-4': { url: '/WordLibs/cet4.json', count: 999 },
  'CET-4-6': { url: '/WordLibs/cet46.json', count: 999 },
  IELTS: { url: '/WordLibs/ielts.json', count: 999 },
  'IELTS-听力词汇': { url: '/WordLibs/ielts-listening.json', count: 999 },
  TOEFL: { url: '/WordLibs/toefl.json', count: 999 },
  GRE: { url: '/WordLibs/gre.json', count: 999 }
}

function LibList(props) {
  const { match, history, location } = props

  console.log(match, location)
  const listID = match.params.listID

  return (
    <div className={styles.hoverable}>
      <header className={styles.header}>
        {listID && (
          <IconButton
            onClick={() => {
              history.push('/lib')
            }}
            className={styles.backBtn}
            size="small"
          >
            <ArrowBackIcon style={{ fontSize: '18px' }} />
          </IconButton>
        )}
        <h3 className={styles.panelHeader}>{listID || 'WordList'}</h3>
      </header>

      {/* lib list */}
      {listID ? (
        <LibWordList
          list={((wordLists[listID].id = listID), wordLists[listID])}
        />
      ) : (
        <>
          {/* <Divider /> */}

          <List className={styles.card} dense>
            {Object.entries(wordLists).map(([name, list]) => {
              return (
                <ListItem
                  onClick={() => {
                    history.push('/lib/' + name.replace(' ', '_'))
                  }}
                  key={name}
                  disableGutters
                  className={styles.listItem}
                  button
                >
                  <ListItemText primary={name} />
                  <ChevronRightIcon className={styles.iconRight} />
                </ListItem>
              )
            })}
          </List>
        </>
      )}
    </div>
  )
}

export default LibList
