import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import Toolbar from '@material-ui/core/Toolbar'
import { Route, Redirect, Switch, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Header from 'components/Header'
import ActivityBar from 'components/ActivityBar'
import WordList from 'components/WordList'
import LibList from 'components/LibList'
import Settings from 'components/Settings'
import DueList from 'components/DueList'
import LearnedList from 'components/LearnedList'
import MainContent from 'components/MainContent'
import WordEntry from 'components/WordEntry'
import TodayAddList from 'components/TodayAddList'

import 'App.scss'
import { Notistack } from 'components/notify'
import { getUserProfile } from 'components/Settings/actions'

function App() {

  useEffect(() => {
    // todo
    // getUserProfile()
  },[])


  return (
    <Grid className="word-app-root" container wrap={'nowrap'}>
      {/* <Header /> */}
      <ActivityBar />
      <Grid className="sideBar">
        <Grid className="fixedContainer">
          <Switch>

            <Route path="/home" component={TodayAddList} />
            <Route path="/due" component={DueList} />
            <Route path="/learned" component={LearnedList} />

            <Route path="/lib/:listID?" component={LibList} />
            <Route path="/settings" component={Settings} />

            <Redirect exact from='/' to='/home' />
          </Switch>

        </Grid>
      </Grid>
      <Grid className="mainContainer">
        <Header />
        <MainContent />
      </Grid>
      <div className="snackBar">
        <Notistack />
      </div>
    </Grid>
  )
}

export default App
