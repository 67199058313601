import get from 'lodash/get'
// 加载有道词典到指定dom
export function yd2Dom(longmanWL, entry_content) {
  let words = longmanWL
  // 同形词
  let wordDoms = entry_content.querySelectorAll('.dictionary > .dictentry')
  let senses = wordDoms[0].querySelectorAll('.Sense.Sense')
  senses.forEach((sense, senseNO) => {
    function createNode(text) {
      let node = document.createElement('span')
      node.style.cssText = `color: #666; font-size: 14px; margin: 0 10px;`
      node.innerText = text
      return node
    }
    let subsenses = sense.querySelectorAll('.Subsense')
    if (subsenses.length === 0) {
      // 常规 1, 2,
      let text = words[0].Entry.Sense[senseNO].TRAN
        ? words[0].Entry.Sense[senseNO].TRAN.join('🐞')
        : '🐞无对应中文解释'
      let cndef = createNode(text)
      void (
        sense.querySelector('.DEF') || sense.querySelector('.Crossref')
      ).after(cndef) // 链接到单独页面的sense
    } else {
      // a), b) ,c...
      subsenses.forEach((sense, NO) => {
        let cndef = createNode(
          words[0].Entry.Sense[senseNO].Subsense[NO].TRAN.join('🐞')
        )
        sense.querySelector('.DEF').after(cndef)
      })
    }
  })
  let exampleMap = {}
  const regexp = /<i>|<\/i>|<em>|<\/em>|\W/g
  words.forEach(({ Entry }) => {
    Entry.Sense.forEach(aSense => {
      get(aSense, 'EXAMPLE', []).forEach((sentense, i) => {
        let plainText = sentense.replace(regexp, '').toLowerCase()
        exampleMap[plainText] = aSense.EXAMPLETRAN[i]
      })
      // 语法例句, 见get的key
      get(aSense, 'GramExa', []).forEach(gram => {
        get(gram, 'EXAMPLE', []).forEach((sentense, i) => {
          let plainText = sentense.replace(regexp, '').toLowerCase()
          exampleMap[plainText] = gram.EXAMPLETRAN[i]
        })
      })
      // subsense 例句
      get(aSense, 'Subsense', []).forEach(aSubsense => {
        get(aSubsense, 'EXAMPLE', []).forEach((sentense, i) => {
          let plainText = sentense.replace(regexp, '').toLowerCase()
          exampleMap[plainText] = aSubsense.EXAMPLETRAN[i]
        })
      })
    })
  })
  wordDoms.forEach(word => {
    word.querySelectorAll('.EXAMPLE').forEach(exampleDom => {
      let trans =
        exampleMap[exampleDom.innerText.replace(regexp, '').toLowerCase()]
      let elP = document.createElement('p')
      elP.innerText = trans
      elP.style.cssText = `
        margin-left: 2.2em;
        font-size: 0.88em;
        color: #999;
      `
      exampleDom.after(elP)
    })
  })
}

(window as any).trans = () => {
  let dom = document.querySelector('.entry_content')
  let examples = dom.querySelectorAll('.Sense .EXAMPLE')
  examples.forEach(example => {
    let cloned = example.cloneNode(true) as HTMLElement
    let container = example.closest('.dictentry-card').querySelector('.clones')

    let { offsetTop, offsetLeft } = example as HTMLElement
    cloned.style.left = String(offsetLeft) + 'px'
    cloned.style.top = String(offsetTop) + 'px'
    cloned.style.position = 'absolute'
    container.appendChild(cloned)
  })
}
