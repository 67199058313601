import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import Toolbar from '@material-ui/core/Toolbar'
import Popper from '@material-ui/core/Popper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Fade from '@material-ui/core/Fade'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import InfoIcon from '@material-ui/icons/Info'
import LoopIcon from '@material-ui/icons/Loop'
import ListIcon from '@material-ui/icons/List'


import styles from './style.module.scss'
import {
  historyGo,
  changeWordEntry,
  fetchLearnedInfo,
  fetchLearnedInfo_
} from 'components/WordEntry/actions'
import { addWord } from 'components/TodayAddList/actions'
import clsx from 'clsx'
import { SearchWithComplete } from './SearchWithComplete'
import { getAutoCompleteList } from './fzsearch';
import { useSelectorT } from 'store';
import { useAsync, useKey } from 'react-use';
import dayjs from 'dayjs';
import InfoPopUp from './InfoPopUp';
import { deleteWord } from 'components/LearnedList/actions';
import { getDueList } from 'components/DueList/actions';
import { message } from 'components/notify'
import { ListItemIcon } from '@material-ui/core'
import ListItem from 'components/WordList/ListItem'
import { ENV_DICT_URL } from 'env'

Object.assign(window, { dayjs })

type wordInfo = {
  word: string
  lv?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7
  [x: string]: any
}
type prop = {
  word: wordInfo
  [x: string]: any
}
export default function Header(props: prop) {

  const isLogedIn = useSelectorT(state => !!state.settings.userProfile)
  const wordInfo = useSelectorT(state => state.wordEntry.wordInfo)

  const dispatch = useDispatch()

  const [addLoading, setAddLoading] = useState(false)

  const learnedInfo = useSelectorT(state => state.wordEntry.learnedInfo)

  const addWordHandler = useCallback(async () => {
    if (!isSuccLoaded || !isExact) {
      return
    }

    if (addLoading || learnedInfo) {
      // 正在或已经添加的条目
      return
    }
    if (isLogedIn) {
      setAddLoading(true)
      try {
        await dispatch(addWord(wordInfo.word))
      } finally {
        setAddLoading(false)
      }
      setCanHover(false)
      setTimeout(() => {
        setCanHover(true)
      }, 1000)
    } else {
      message('必须先登录才能添加单词', { variant: 'error', autoHideDuration: 2000 })
    }
  }, [dispatch, wordInfo, isLogedIn, learnedInfo, addLoading])

  const goMainEntry = useCallback(
    () => {
      dispatch(changeWordEntry({ word: wordInfo.wordEntryTitle }))
      console.log('dispatch')
    },
    [dispatch, wordInfo.wordEntryTitle]
  )

  const addHandler = useCallback((e) => {
    if (['INPUT', 'TEXTAREA'].includes(e.target.tagName)) {
      return
    }
    if (e.metaKey || e.repeat || e.ctrlKey) {
      return
    }
    addWordHandler()
  }, [addWordHandler])
  useKey('a', addHandler, { event: "keypress" }, [addHandler])


  const confirmDelete = useCallback(() => {
    if (!learnedInfo) {
      return
    }

    dispatch(deleteWord(learnedInfo.word))
    // 更新相关列表
    dispatch(getDueList())
    dispatch(fetchLearnedInfo_(learnedInfo.word))
    setAnchorEl(null)

  }, [learnedInfo])
  const shortCutHandler = useCallback((e) => {
    if (['INPUT', 'TEXTAREA'].includes(e.target.tagName)) {
      return
    }
    if (!e.metaKey || e.repeat || e.ctrlKey) {
      return
    }
    confirmDelete()
  }, [confirmDelete])
  useKey('Backspace', shortCutHandler, {}, [shortCutHandler])



  // 考试类别
  const { value: exams = [] } = useAsync(async () => {
    const url = ENV_DICT_URL
    let jsonO = await fetch(`${url}yd/${wordInfo.word}.json`).then(r => r.json())
    return jsonO.exam_type
  }, [wordInfo.word])

  // 添加完单词后，鼠标移开前，图标 √ -> i 不要响应
  const [canHover, setCanHover] = useState(true)
  const handleMLeave = useCallback(() => {
    setCanHover(true)
  }, [setCanHover])

  // 前进后退
  const history = useSelectorT(state => state.wordEntry.history)
  const historyAt = useSelectorT(state => state.wordEntry.historyAt)
  const goForward = useCallback(() => dispatch(historyGo(1)), [dispatch])
  const goBackward = useCallback(() => dispatch(historyGo(-1)), [dispatch])
  const goForwardDisabled = historyAt + 1 === history.length
  const goBackwardDisabled = historyAt < 1

  const search = useCallback(wordInfo => dispatch(changeWordEntry(wordInfo)), [
    dispatch
  ])

  // info popper
  const [anchorEl, setAnchorEl] = React.useState(null)
  function handleInfoClick(event) {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  function handleInfoClickAway() {
    setAnchorEl(null)
  }

  const id = anchorEl ? 'simple-popper' : undefined

  const [allSuggestionWords, setAllSuggestionWords] = useState<string[]>([])
  useEffect(() => {
    setTimeout(() => {
      getAutoCompleteList()
        .then(setAllSuggestionWords)
    }, 2000)
    return
  }, [])

  const isLongWord = wordInfo.word.length > 15
  /** entry的title 和 word是否相同。有时某个单词作为一个词条的附属 */
  const isExact = wordInfo.word === (wordInfo.wordEntryTitle && wordInfo.wordEntryTitle.toLowerCase())
  /** 是否成功加载，true: entry有title; false: 404 或 loading状态 */
  const isSuccLoaded = !!wordInfo.wordEntryTitle

  return (
    <div className={styles.toolbarPh}>
      <Toolbar className="toolbar" variant="dense">
        <h2 draggable onDragStart={(e)=>{
          const dragImage = document.createElement('div');
          dragImage.style.cssText = `
            display: inline-block;
            font-size: 12px;
            position: fixed;
            z-index: 1210;
            border-radius: 13px;
            height: 24px;
            font-weight: bold;
            background-color: rgb(22, 144, 182);
            color: white;
            padding: 0 12px;
            top: 0;
            line-height: 24px;
            vertical-align: middle;
            left: 9999px;
          `
          dragImage.textContent = wordInfo.word;
          document.body.appendChild(dragImage);
          const width = dragImage.getBoundingClientRect().width;
          e.dataTransfer.setDragImage(dragImage, width / 1.5, 14);
          setTimeout(() => document.body.removeChild(dragImage), 0);
        }} className={clsx({ smaller: isLongWord })}>{wordInfo.word}</h2>
        <span className="learned-times">lv{learnedInfo && learnedInfo.lv}</span>

        {
          (() => {
            if (!isSuccLoaded) {
              return
            } else if (!isExact) {
              // entry 正常 loaded，且word和title不同
              return (
                <IconButton
                  className={clsx('addBtn')}
                  size="small"
                  onClick={goMainEntry}
                  onMouseLeave={handleMLeave}
                >
                  <LoopIcon></LoopIcon>
                </IconButton>
              )
            } else if (learnedInfo) {
              // 已添加
              return (<ClickAwayListener onClickAway={handleInfoClickAway}>
                <span>
                  <IconButton
                    className={clsx('addBtn', 'switch', canHover && 'canHover')}
                    size="small"
                    onClick={handleInfoClick}
                  >
                    <CheckCircleIcon />
                    <InfoIcon color="primary" />
                  </IconButton>
                  {/* info popper */}
                  <Popper
                    disablePortal
                    id={id}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    transition
                  >
                    {({ TransitionProps }) => (

                      <Fade {...TransitionProps} timeout={350}>
                        <div>
                          <InfoPopUp deleteWord={confirmDelete} learnedInfo={learnedInfo}></InfoPopUp>
                        </div>
                      </Fade>
                    )}
                  </Popper>
                </span>
              </ClickAwayListener>)
            } else {
              // 未添加
              return (
                <IconButton onClick={addWordHandler} className="addBtn" size="small">
                  <AddCircleIcon color="primary" />
                </IconButton>
              )
            }


          })()
        }

        {/* <CancelIcon color="error" /> */}

        <span className="listNames">{exams.join(' / ')}</span>

        <div className="right">
            <ClickAwayListener onClickAway={handleInfoClickAway}>
                <span>
                  <IconButton
                    size="small"
                    onClick={handleInfoClick}
                    onMouseLeave={handleMLeave}
                  >
                    {/* <InfoIcon color="primary" /> */}
                    <ListIcon />
                  </IconButton>
                  {/* info popper */}
                  <Popper
                    disablePortal
                    id={id}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    transition
                  >
                    {({ TransitionProps }) => (
                      <Fade {...TransitionProps} timeout={350}>
                        <Paper style={{ maxHeight: 600, overflowY: 'auto' }}>
                          {[...history].reverse().map((word, i) => {
                            const itemHistoryIndex = history.length - 1 - i
                            return <ListItem
                              selected={historyAt === itemHistoryIndex}
                            select={()=>{
                              
                              dispatch(historyGo(itemHistoryIndex - historyAt))
                            }} wordItem={{word: `${word.word}` + (word.date ? ` - ${new Date(word.date).getDate()}` : ''), id: word.word}}></ListItem>
                          })}
                        </Paper>
                      </Fade>
                    )}
                  </Popper>
                </span>
              </ClickAwayListener>

          <div className="arrows">
            <IconButton
              onClick={goBackward}
              
              disabled={goBackwardDisabled}
              size="small"
            >
              <ArrowBackIcon />
            </IconButton>
            <IconButton
              onClick={goForward}
              disabled={goForwardDisabled}
              size="small"
            >
              <ArrowForwardIcon />
            </IconButton>
          </div>

          {/* <Search search={search} /> */}
          <SearchWithComplete data={allSuggestionWords} search={search} />
        </div>
      </Toolbar>
    </div>
  )
}
