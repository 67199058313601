import { ENV_DICT_URL } from 'env'
import './ipa.scss'


export function transIpaDom(pronEl: HTMLSpanElement) {
    function splitIPA(pronCodes: string) {
      // short: i, u
      const ipasDouble = ['eɪ', 'aɪ', 'ɔɪ', 'əʊ', 'aʊ', 'eə', 'ɪə', 'ʊə', 'tʃ', 'dʒ', 'ɔː', 'iː', 'ɑː', 'uː', 'ɜː',]
      const ipasSingle = ['p', 'b', 't', 'd', 'k', 'ɡ', 'm', 'n', 'ŋ', 'f', 'v', 'θ', 'ð', 's', 'z', 'ʃ', 'ʒ', 'h', 'r', 'l', 'j', 'w', 'ɪ', 'e', 'æ', 'ʌ', 'ɒ', 'ʊ', 'ə',]
      const result: { text: string, isIpa: boolean }[] = []
      for (let i = 0; i < pronCodes.length; i++) {
        const currentChar = pronCodes[i]
        const currTwo = currentChar + (pronCodes[i + 1] || '')
        if (ipasDouble.includes(currTwo)) {
          result.push({ text: currTwo, isIpa: true })
          i++ // skip next
          continue
        }
  
        // single
        const currIsIpa = ipasSingle.includes(currentChar)
  
        result.push({ text: currentChar, isIpa: currIsIpa })
      }
  
      return result
    }
  
    if (!pronEl) return;
    const pronText = pronEl.innerText
    pronEl.innerText = ''
    const nodeEls = splitIPA(pronText).forEach(nodeObj => {
      const el = document.createElement('span')
      el.innerText = nodeObj.text
      el.style.cssText = `
          // cursor: ${nodeObj.isIpa ? 'pointer' : 'default'};
          // color: ${nodeObj.isIpa ? 'grey' : 'inherit'};
          // font-weight: ${nodeObj.isIpa ? 'bold' : 'normal'};
          // letter-spacing: 4px;
          padding: 0 2px;
        `
      if (nodeObj.isIpa) {
        el.dataset.isIpa = nodeObj.isIpa.toString()
      }
      el.addEventListener('click', function (e) {
        if (this.dataset.isIpa) {
          new Audio(ENV_DICT_URL + 'ipa/' + nodeObj.text + '.mp3').play()
        }
      })
      pronEl.appendChild(el)
    })
  }