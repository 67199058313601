export const blur = (dom) => {
    // 这部分元素可以点击变清晰
    const responseEl = [
        '.DEF',
        '.EXAMPLE',
        '.Hint',
        '.COLLO',
        '.Crossref',
        '.PROPFORM',
        '.PROPFORMPREP',
        '.ColloBox',
        '.GramBox',
        '.ThesBox',
        '.F2NBox',
    ]
    const whiteList = [
        '.Head'
    ]

    // 查找blur应用的目标
    // 1.可控制元素必须被单独blur
    // 2.祖先元素和后代不能同时blur
    // 3.所有叶子元素最终都被blur
    let dictentrys = dom.querySelectorAll('.dictionary .dictentry')
    dictentrys.forEach(dictentry => {
        function r(root: Element) {
            const elInfo: [Element, boolean][] = []
            for (const el of Array.from(root.children)) {
                if (el.matches(responseEl.join(','))) {
                    el.classList.add('blur', 'blur-response')
                    elInfo.push([el, true])
                    continue
                }
                if (!el.children.length) {
                    elInfo.push([el, false])
                    continue
                }

                let elHas = r(el)
                elInfo.push([el, elHas])
            }

            const rootHas = elInfo.some(pair => pair[1])
            if (rootHas) {
                elInfo.filter(pair => !pair[1]).forEach(([el]) => !el.matches(whiteList.join(',')) && el.classList.add('blur'))
            }
            return rootHas
        }

        r(dictentry)

    })
    dom.querySelectorAll('.blur-response').forEach(el => {
        el.addEventListener('click', (e) => {
            (e.currentTarget as HTMLSpanElement).classList.add('show')
        })
    })
}