import React, { useState, useMemo, useCallback, useEffect } from 'react'
import WordEntry from 'components/WordEntry'
import ReviewOption from 'components/ReviewOption'
import { withRouter } from 'react-router-dom'
import style from './style.module.scss'
import { Button } from '@material-ui/core'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import { reviewNext } from 'components/DueList'
import { CSSTransition } from 'react-transition-group'
import clsx from 'clsx';
import { useSelectorT } from 'store';
import { Related } from 'components/WordEntry/Related';
import { useDispatch } from 'react-redux';
import { changeWordEntry } from 'components/WordEntry/actions';

export type ReviewState =
  | 'recall'
  | 'feedback'
  | 'done_good'
  | 'done_bad'
  | 'origin'
  | 'all_done'

function MainContent(props) {
  const dispatch = useDispatch()

  useEffect(()=>{
    ( window as any ).changeWord = (w)  => dispatch(changeWordEntry({ word: w }))    
  }, [])

  const wordInfo = useSelectorT(state => state.wordEntry.wordInfo)
  const isWordEntryReview = wordInfo.isReview

  const [reviewState, setReviewState] = useState<ReviewState>('recall')

  const isDueList = props.location.pathname === '/due'
  const dueRemains = useSelectorT(state => state.due.list)
    .filter(v => !v.dueReviewed).length > 0

  useMemo(() => {
    if (!isDueList) {
      setReviewState('recall')
    }
  }, [isDueList])

  const relClickHandler = useCallback((word) => {
    dispatch(changeWordEntry({ word }))
  }, [dispatch])

  return (
    <div className={clsx('center', style.MainContent)}>
      <main>

        <CSSTransition
          unmountOnExit
          in={isDueList && !isWordEntryReview}
          timeout={2000}
          classNames="goreviewbar-trans"
        >
          <GoReviewBar done={!dueRemains} />
        </CSSTransition>
        <CSSTransition
          unmountOnExit
          in={isDueList && isWordEntryReview}
          timeout={2000}
          classNames="ReviewOption-trans"
        >
          <ReviewOption
            reviewState={reviewState}
            setReviewState={setReviewState}
          />
        </CSSTransition>

        <WordEntry
          blured={isWordEntryReview && reviewState === 'recall'}
          word={wordInfo.word}
        />
      </main>
      <aside>
        <Related word={wordInfo.word} onWordClick={relClickHandler}></Related>
      </aside>
    </div>
  )
}
export default withRouter(MainContent)

function GoReviewBar({ done }) {
  return (
    <div
      className={style.GoReviewBar}
      onClick={() => {
        !done && reviewNext()
      }}
    >
      {
        !done &&
        (
          <>
            <PlayCircleOutlineIcon />
            <span>
              进入复习
          </span>
          </>
        )
      }
      {
        done && '没有要复习的了'
      }
    </div>
  )
}
