import { reviewDoneServer } from "local-server/learned";
import { createActionThunk } from 'redux-thunk-actions'
import { api, isErrResp } from "api";

export const T_ReviewDone = 'T_ReviewDone'
export function reviewDone({ word, lv, retry }) {
    let list = reviewDoneServer({ word, lv, retry })
    return { type: T_ReviewDone, list }
}


export const getDueList = createActionThunk('GET_DUE_LIST', async () => {
    const resp = await api.getDueList()
    if (isErrResp(resp)) {
        throw new Error(resp.error)
    }
    return resp
})


export const reviewDon_ = createActionThunk('GET_DUE_LIST', async () => {
    const resp = await api.getDueList()
    if (isErrResp(resp)) {
        throw new Error(resp.error)
    }
    return resp
})