import { api, isErrResp } from "api"

// 自动发音
export const T_SETTINGS_SETAUTOPRONOUNCE = 'Settings/setAutoPronounce'
export function setAutoPronounce(to: boolean) {
  return { type: T_SETTINGS_SETAUTOPRONOUNCE, to }
}


// 例句显示翻译
export const T_SETTINGS_SETSHOWTRANS = 'Settings/setShowTrans'
export function setShowTrans(to: boolean) {
  return { type: T_SETTINGS_SETSHOWTRANS, to }
}




export const T_GET_USER_PROFILE = 'Settings/getUserProfile'
export function getUserProfile() {
  return async function (dispatch) {
    const resp = await api.getProfile()
    if (isErrResp(resp)){
      throw new Error()
    }
    if (resp._id) {
      dispatch(receiveUserProfile(resp))
    }
  }
}

export const T_RECEIVE_USER_PROFILE = 'T_RECEIVE_USER_PROFILE'
function receiveUserProfile(data) {
  return {
    type: T_RECEIVE_USER_PROFILE,
    data,
  }
}

