import React, { useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core'
import flatten from 'lodash/flatten'
import get from 'lodash/get'
import { useAsync } from 'react-use';
import { ENV_DICT_URL } from 'env'

interface Props {
  word: string
  onWordClick: (word: string) => void
}

export function Related(props: Props) {
  const {word, onWordClick} = props
  const result = useAsync(async () => {
    const url = ENV_DICT_URL
    let jsonO = await fetch(`${url}yd/${word}.json`).then(r => r.json())
    return jsonO.rel_word
  }, [word])

  if (result.loading) {
    return <div>loading...</div>
  }

  const transed = flatten(
    get(result.value, 'rels', []).map(group => {
      return group.rel.words.map(word => {
        word.pos = group.rel.pos
        return word
      })
    })
  )

  if (!transed.length) {
    return <div>no content</div>
  }

  return (
    <Paper>
      <Typography variant="h5" color="textSecondary" gutterBottom>
        {/* {props.match.params.word} word family */}
      </Typography>

      <List>
        {transed.map(({ word, tran, pos }) => (
          <ListItem button key={word + pos} onClick={() => onWordClick( word )}>
            <ListItemText primary={word} secondary={tran} />

            {/* <Typography variant="h5" gutterBottom>{word}</Typography>
            <Typography color="textSecondary" gutterBottom>{tran}</Typography> */}
          </ListItem>
        ))}
      </List>
    </Paper>
  )
}
